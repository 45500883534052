import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageIds, UiFieldCtrDef, ValidationPatterns } from '@app-com/constants/patterns';
import { ResourcePipe } from '@app-com/pipes';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { isEqual } from 'date-fns';
import { Subscription } from 'rxjs';
import { SepoProjectOutcomeDto, SepoProjectOutcomeUpdateDto } from '@app-com/api/models';
import { formatNumberWithCommas } from '@app-com/directives';

@Component({
  selector: 'app-edit-sepo-project-outcomes-modal',
  templateUrl: './edit-sepo-project-outcomes-modal.component.html',
  styleUrl: './edit-sepo-project-outcomes-modal.component.scss',
})
export class EditSepoProjectOutcomesModalComponent {
  public PageIds = PageIds;
  CurrentPageDef = PageIds.sepo.immediateOutcomesProjectUpdates;
  public ValidationPatterns = ValidationPatterns;
  pageId = 'SEPO';

  projectEditForm: FormGroup;
  UiDefApplicationId: UiFieldCtrDef;
  UiDefApplicationName: UiFieldCtrDef;
  UiDefProjectId: UiFieldCtrDef;
  UiDefProjectName: UiFieldCtrDef;
  UiDefPrimaryCapitalAsset: UiFieldCtrDef;
  UiDefQuantityNew: UiFieldCtrDef;
  UiDefQuantityUpgrade: UiFieldCtrDef;
  UiDefCurrentAssetRanking: UiFieldCtrDef;
  UiDefPrimaryProjectOutcome: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];
  submitClicked = false;
  sub = new Subscription();
  primaryCapitalAssetType: string | undefined;
  primaryFunctionalCategoryType: string | undefined;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  quantityZeroValidationMessage = '"Quantity:new" and/or "Quantity:upgrade" must be greater than 0.';

  @Input() capitalAssetMeasurementUnit: string = '';
  @Input() projectOutcomeId: number;
  @Input() sepoProjectOutcomeToUpdate?: SepoProjectOutcomeDto;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() allCapitalAssetTypes: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() functionalCategoryTypes: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() assetConditionRankings: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() primaryOutcomes: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() isProjectValid: EventEmitter<any> = new EventEmitter();
  @Output() saveProjectOutcome: EventEmitter<SepoProjectOutcomeDto> = new EventEmitter<SepoProjectOutcomeDto>();

  calloutRef: ElementRef;
  @ViewChild('callout', { read: ElementRef, static: false }) set content(content: ElementRef) {
    if (content) {
      this.calloutRef = content;
    }
  }
  isAlive = true;

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
  ) {}

  ngOnInit(): void {
    console.log(this.allCapitalAssetTypes);
    console.log(this.sepoProjectOutcomeToUpdate?.primaryCapitalAssetTypeId);
    this.primaryCapitalAssetType = this.allCapitalAssetTypes.find(
      (x) => x.id === this.sepoProjectOutcomeToUpdate?.primaryCapitalAssetTypeId,
    )?.title;

    const primaryFunctionalCategory = this.sepoProjectOutcomeToUpdate?.functionalCategories.find(
      (category) => category.isPrimary,
    );
    if (primaryFunctionalCategory) {
      this.primaryFunctionalCategoryType = this.functionalCategoryTypes.find(
        (x) => x.id == primaryFunctionalCategory.functionalCategoryTypeId.toString(),
      )?.title;
    }

    this.createProjectEditForm();
    this.initializeProjectToUpdate();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeCommaFormat(val: any) {
    if (typeof val == 'string') {
      const str = val.replace(/,/g, '');
      return parseFloat(str);
    }
    return val;
  }

  validateValues(): boolean {
    const areAllValuesNull = Object.values(this.projectEditForm.value).every((value) => value === null || value === '');

    if (areAllValuesNull) {
      return true;
    }
    this.projectEditForm.markAllAsTouched();
    this.pageUiDefs.forEach((uiDef) => {
      CommUtilsService.getUiFieldErrorList(uiDef, true, 'nextCheckError');
    });

    if (
      this.projectEditForm?.valid &&
      !this.shouldShowQuantityZeroValidationMessage &&
      !this.shouldShowQuantityNewNegativeValidationMessage &&
      !this.shouldShowQuantityUpgradeNegativeValidationMessage
    ) {
      return true;
    } else {
      setTimeout(() => {
        this.jumpToCallout(this.calloutRef);
      }, 100);
      return false;
    }
  }
  // convert page UI-data into model, before sending to back-end
  _getProjectUpdate(): SepoProjectOutcomeUpdateDto {
    const project: SepoProjectOutcomeUpdateDto = {
      actualQuantityNew: this.projectEditForm?.value[this.UiDefQuantityNew.nameCtr],
      actualQuantityUpgrade: this.projectEditForm?.value[this.UiDefQuantityUpgrade.nameCtr],
      assetConditionRankingId: this.projectEditForm?.value[this.UiDefCurrentAssetRanking.nameCtr],
      projectPrimaryOutcomeId: this.projectEditForm?.value[this.UiDefPrimaryProjectOutcome.nameCtr],
    };

    const projectValWithoutFormatting = {
      ...project,
      actualQuantityNew: this.removeCommaFormat(project.actualQuantityNew),
      actualQuantityUpgrade: this.removeCommaFormat(project.actualQuantityUpgrade),
    };
    return projectValWithoutFormatting;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
  }

  createProjectEditForm() {
    this.projectEditForm = this.formBuilder.group({});

    this.UiDefQuantityNew = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.quantityNew);
    this.projectEditForm.addControl(this.UiDefQuantityNew.nameCtr, this.UiDefQuantityNew.formCtr);

    this.UiDefQuantityUpgrade = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.quantityUpgrade);
    this.projectEditForm.addControl(this.UiDefQuantityUpgrade.nameCtr, this.UiDefQuantityUpgrade.formCtr);

    this.UiDefCurrentAssetRanking = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.currentAssetRanking);
    this.projectEditForm.addControl(this.UiDefCurrentAssetRanking.nameCtr, this.UiDefCurrentAssetRanking.formCtr);

    this.UiDefPrimaryProjectOutcome = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.projectPrimaryOutcome);
    this.projectEditForm.addControl(this.UiDefPrimaryProjectOutcome.nameCtr, this.UiDefPrimaryProjectOutcome.formCtr);

    this.pageUiDefs = [
      this.UiDefQuantityNew,
      this.UiDefQuantityUpgrade,
      this.UiDefCurrentAssetRanking,
      this.UiDefPrimaryProjectOutcome,
    ];
  }

  initializeProjectToUpdate() {
    this.projectEditForm?.setValue({
      [this.UiDefQuantityNew.nameCtr]: formatNumberWithCommas(this.sepoProjectOutcomeToUpdate?.actualQuantityNew),
      [this.UiDefQuantityUpgrade.nameCtr]: formatNumberWithCommas(
        this.sepoProjectOutcomeToUpdate?.actualQuantityUpgrade,
      ),
      [this.UiDefCurrentAssetRanking.nameCtr]: this.sepoProjectOutcomeToUpdate?.assetConditionRankingId,
      [this.UiDefPrimaryProjectOutcome.nameCtr]: this.sepoProjectOutcomeToUpdate?.projectPrimaryOutcomeId,
    });
  }

  areDatesEqual(dt1: string, dt2: string) {
    const firstDt = CommUtilsService._getFormattedDate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      dt1!,
    );
    const secondDt = CommUtilsService._getFormattedDate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      dt2!,
    );
    const eq = isEqual(firstDt as string, secondDt as string);
    return eq;
  }

  get shouldShowQuantityZeroValidationMessage(): boolean {
    return (
      ((this.projectEditForm?.get('quantityNew')?.touched ?? false) ||
        (this.projectEditForm?.get('quantityUpgrade')?.touched ?? false)) &&
      this._quantityZeroValidator()
    );
  }

  get shouldShowQuantityNewNegativeValidationMessage(): boolean {
    const rawQuantityNew = this.removeCommaFormat(this.projectEditForm.get('quantityNew')?.value) ?? '';
    if (rawQuantityNew.length == 0) {
      return false;
    }
    if (!/^[0-9]+$/.test(rawQuantityNew)) {
      return true;
    }
    const actualQuantityNew =
      typeof rawQuantityNew == 'string' ? CommUtilsService.parseInputInt(rawQuantityNew) : rawQuantityNew;
    return (this.projectEditForm?.get('quantityNew')?.touched ?? false) && actualQuantityNew < 0;
  }

  get shouldShowQuantityUpgradeNegativeValidationMessage(): boolean {
    const rawQuantityUpgrade = this.removeCommaFormat(this.projectEditForm.get('quantityUpgrade')?.value) ?? '';
    if (rawQuantityUpgrade.length == 0) {
      return false;
    }
    if (!/^[0-9]+$/.test(rawQuantityUpgrade)) {
      return true;
    }
    const actualQuantityUpdate =
      typeof rawQuantityUpgrade == 'string' ? CommUtilsService.parseInputInt(rawQuantityUpgrade) : rawQuantityUpgrade;
    return (this.projectEditForm?.get('quantityUpgrade')?.touched ?? false) && actualQuantityUpdate < 0;
  }

  private _quantityZeroValidator(): boolean {
    const actualQuantityNew =
      typeof this.projectEditForm.get('quantityNew')?.value == 'string'
        ? CommUtilsService.parseInputInt(this.projectEditForm.get('quantityNew')?.value)
        : this.projectEditForm.get('quantityNew')?.value;

    const actualQuantityUpgrade =
      typeof this.projectEditForm.get('quantityUpgrade')?.value == 'string'
        ? CommUtilsService.parseInputInt(this.projectEditForm.get('quantityUpgrade')?.value)
        : this.projectEditForm.get('quantityUpgrade')?.value;

    if (actualQuantityNew === 0 && actualQuantityUpgrade === 0) {
      return true;
    }

    return false;
  }
  get showErrorFieldsCallout(): boolean {
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;
    return (
      baseHasError ||
      this.shouldShowQuantityZeroValidationMessage ||
      this.shouldShowQuantityNewNegativeValidationMessage ||
      this.shouldShowQuantityUpgradeNegativeValidationMessage
    );
  }

  jumpToField(fieldName: string) {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      //console.error('Cannot find linked field: ' + fieldName);
    }
  }

  jumpToCallout(fieldTemplate: ElementRef) {
    if (fieldTemplate) {
      const fieldElement = fieldTemplate.nativeElement;
      if (fieldElement) {
        fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } else {
        //console.error('Cannot find linked field: ' + fieldTemplate.nativeElement);
      }
    }
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(UiDef: UiFieldCtrDef, isBlurAway: boolean) {
    if (isBlurAway) {
      UiDef.focusedInNonBlankOrErrorField = true;
    }

    if (isBlurAway) {
      setTimeout(() => {
        // Goa-input 1.16.0 has a bug, on blur, it fires:
        // 1. focusOut-event with null value first (even user picked an item in drop-down-list
        // 2. formControl-change-event with user-selected-valid-key after focusOut event
        // 3. old library fire focusOut-event with valid-ket last
        // to support both old and new library behaviour. I delayed the focusOut-event-process to avoid wrong-empty-key-error
        CommUtilsService.getUiFieldErrorList(UiDef, isBlurAway, 'focusOut');

        // if (UiDef.nameCtr === this.CurrentPageDef.anticipatedEndDate.nameCtr) {
        //   if (CommUtilsService.isDateInputValueValid(this.UiDefAnticipatedStartDate)) {
        //     CommUtilsService.getUiFieldErrorList(this.UiDefAnticipatedStartDate, true, 'crossFocusOut');
        //   }
        // } else if (UiDef.nameCtr === this.CurrentPageDef.anticipatedStartDate.nameCtr) {
        //   if (CommUtilsService.isDateInputValueValid(this.UiDefAnticipatedEndDate)) {
        //     CommUtilsService.getUiFieldErrorList(this.UiDefAnticipatedEndDate, true, 'crossFocusOut');
        //   }
        // }
      }, 400);
    }
  }

  public hasProjectFormAnyError(submitted: boolean): boolean {
    this.submitClicked = this.submitClicked || submitted;

    return !this.projectEditForm.valid;
  }
}
