<div class="mb-56px" style="margin-top: 0.75rem" *ngIf="showErrorFieldsCallout">
  <goa-callout
    #callout
    type="emergency"
    heading="{{ 'errorCalloutHeading' | res: 'COMMON' }}"
    *ngIf="showErrorFieldsCallout"
  >
    <ng-container *ngFor="let uiDef of pageUiDefs">
      <a *ngIf="uiDef.errorMsg" (click)="jumpToField(uiDef.idWrap ?? '')" class="validation-message-link">
        {{ uiDef.labelText }}
        <br />
      </a>
    </ng-container>

    <!-- show extra custom error, and prevent duplicate field-label showing -->
    <a
      *ngIf="(UiDefQuantityNew.errorMsg || shouldShowQuantityZeroValidationMessage) && !UiDefQuantityNew.errorMsg"
      (click)="jumpToField(UiDefQuantityNew.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefQuantityNew.labelText }}<br
    /></a>
    <a
      *ngIf="shouldShowQuantityNewNegativeValidationMessage && !UiDefQuantityNew.errorMsg"
      (click)="jumpToField(UiDefQuantityNew.id + '-wrap')"
      class="validation-message-link"
      >"Quantity:new" should be a positive number.<br />
    </a>
    <a
      *ngIf="
        (UiDefQuantityUpgrade.errorMsg || shouldShowQuantityZeroValidationMessage) && !UiDefQuantityUpgrade.errorMsg
      "
      (click)="jumpToField(UiDefQuantityUpgrade.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefQuantityUpgrade.labelText }}<br
    /></a>
    <a
      *ngIf="shouldShowQuantityUpgradeNegativeValidationMessage && !UiDefQuantityUpgrade.errorMsg"
      (click)="jumpToField(UiDefQuantityUpgrade.id + '-wrap')"
      class="validation-message-link"
      >"Quantity:update" should be a positive number.<br />
    </a>
  </goa-callout>
</div>
<form [formGroup]="projectEditForm">
  <div class="edit-project-container">
    <goa-form-item [label]="'Application ID'" id="application-id">
      <p>{{ this.sepoProjectOutcomeToUpdate?.applicationIdTxt ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Application name'" id="application-name" class="application-name">
      <p>{{ this.sepoProjectOutcomeToUpdate?.applicationName ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Primary functional category'" id="primary-functional-category">
      <p>{{ this.primaryFunctionalCategoryType ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Project ID'" id="project-id">
      <p>{{ this.sepoProjectOutcomeToUpdate?.projectIdTxt ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Project name'" id="project-name" class="project-name">
      <p>{{ this.sepoProjectOutcomeToUpdate?.projectName ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <goa-form-item [label]="'Primary capital asset'" id="primary-capital-asset" class="primary-capital-asset">
      <p>{{ this.primaryCapitalAssetType ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="s"></goa-spacer>
    <div class="fi-container-pb" *ngIf="UiDefQuantityNew; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            width="285px"
            type="text"
            autocomplete="off"
            commaFormatNumber
            [allowZero]="true"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg || shouldShowQuantityZeroValidationMessage"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="trailingContent">
              <span class="capital-asset-measurement-unit">{{ capitalAssetMeasurementUnit }}</span>
            </div>
          </goa-input>

          <div
            *ngIf="
              UiDef.errorMsg ||
              shouldShowQuantityZeroValidationMessage ||
              shouldShowQuantityNewNegativeValidationMessage
            "
            class="error-msg amount-error-msg"
          >
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)" class="d-flex">
                <goa-icon type="warning" size="small" theme="filled" class="icon-margin" />
                {{ error }}
              </div>
            </div>
            <div *ngIf="shouldShowQuantityZeroValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'" class="d-flex">
                <goa-icon type="warning" size="small" theme="filled" class="icon-margin" />
                {{ quantityZeroValidationMessage }}
              </div>
            </div>
          </div>
          <div *ngIf="shouldShowQuantityNewNegativeValidationMessage" class="long-error-msg">
            <div [id]="UiDef.nameCtr + '-error'" class="d-flex">
              <goa-icon type="warning" size="small" theme="filled" class="icon-margin" />
              "Quantity:new" should be a positive number.
            </div>
          </div>
        </goa-form-item>
      </div>
    </div>
    <goa-spacer vSpacing="s"></goa-spacer>
    <div class="fi-container-pb" *ngIf="UiDefQuantityUpgrade; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            width="285px"
            type="text"
            autocomplete="off"
            commaFormatNumber
            [allowZero]="true"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg || shouldShowQuantityZeroValidationMessage"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="trailingContent">
              <span class="capital-asset-measurement-unit">{{ capitalAssetMeasurementUnit }}</span>
            </div>
          </goa-input>

          <div
            *ngIf="
              UiDef.errorMsg ||
              shouldShowQuantityZeroValidationMessage ||
              shouldShowQuantityUpgradeNegativeValidationMessage
            "
            class="error-msg amount-error-msg"
          >
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div [id]="UiDef.nameCtr + '-error-' + (errNum + 1)" class="d-flex">
                <goa-icon type="warning" size="small" theme="filled" class="icon-margin" />
                {{ error }}
              </div>
            </div>
            <div *ngIf="shouldShowQuantityZeroValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'" class="d-flex">
                <goa-icon type="warning" size="small" theme="filled" class="icon-margin" />
                {{ quantityZeroValidationMessage }}
              </div>
            </div>
          </div>
          <div *ngIf="shouldShowQuantityUpgradeNegativeValidationMessage" class="long-error-msg">
            <div [id]="UiDef.nameCtr + '-error'" class="d-flex">
              <goa-icon type="warning" size="small" theme="filled" class="icon-margin" />
              "Quantity:update" should be a positive number.
            </div>
          </div>
        </goa-form-item>
      </div>
    </div>
    <goa-spacer vSpacing="s"></goa-spacer>
    <div *ngIf="UiDefCurrentAssetRanking; let UiDef" class="gene-app-info-wd">
      <goa-form-item
        class="app-section-item"
        [attr.id]="UiDef.id + '-wrap'"
        [label]="UiDef.labelText"
        [helptext]="UiDef.helptext"
      >
        <goa-dropdown
          width="665px"
          maxheight="250px"
          relative="true"
          name="{{ UiDef.name }}"
          [attr.id]="UiDef.id"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          (focusin)="onFocusIn(UiDef, true)"
          (focusout)="onFocusOut(UiDef, true)"
        >
          <goa-dropdown-item
            *ngFor="let assetConditionRanking of assetConditionRankings"
            id="{{ UiDefCurrentAssetRanking.id }} + {{ assetConditionRanking.id + 1 }}"
            [value]="assetConditionRanking.id"
            [label]="
              assetConditionRanking.id + '. ' + assetConditionRanking.title + ': ' + assetConditionRanking.description
            "
          >
          </goa-dropdown-item>
        </goa-dropdown>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div class="error-icon-container d-flex" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" class="icon-margin" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
    </div>
    <goa-spacer vSpacing="s"></goa-spacer>
    <div *ngIf="UiDefPrimaryProjectOutcome; let UiDef" class="gene-app-info-wd">
      <goa-form-item
        class="app-section-item"
        [attr.id]="UiDef.id + '-wrap'"
        [label]="UiDef.labelText"
        [helptext]="UiDef.helptext"
      >
        <goa-dropdown
          width="665px"
          maxheight="250px"
          relative="true"
          name="{{ UiDef.name }}"
          [attr.id]="UiDef.id"
          [placeholder]="UiDef.placeholder"
          [error]="UiDef.errorMsg"
          formControlName="{{ UiDef.nameCtr }}"
          goaValue
          [value]="UiDef.formCtr.value"
          (focusin)="onFocusIn(UiDef, true)"
          (focusout)="onFocusOut(UiDef, true)"
        >
          <goa-dropdown-item
            *ngFor="let primaryOutcome of primaryOutcomes"
            id="{{ UiDefPrimaryProjectOutcome.id }} + {{ primaryOutcome.id + 1 }}"
            [value]="primaryOutcome.id"
            [label]="primaryOutcome.title"
          >
          </goa-dropdown-item>
        </goa-dropdown>
        <div *ngIf="UiDef.errorMsg" class="error-msg">
          <div *ngFor="let error of UiDef.errorMsg; index as errNum">
            <div class="error-icon-container d-flex" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
              <goa-icon class="error-icon-spacing icon-margin" type="warning" size="small" theme="filled" />
              {{ error }}
            </div>
          </div>
        </div>
      </goa-form-item>
    </div>
    <goa-spacer vSpacing="s"></goa-spacer>
  </div>
</form>
