import { Injectable } from '@angular/core';
import {
  ApplicationDraftDto,
  ApplicationProjectType,
  ApplicationDraftRecordDto,
  CreateApplicationDraftDto,
  ProjectDraftDto,
} from '@app-com/api/models';
import { ApplicationExtService } from '@app-com/api/services';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  AutoSaveApplicationDraft,
  DeleteProjects,
  FetchApplicationDraft,
  SaveApplicationDraft,
  SetApplicationDraftId,
  DeleteApplicationDraft,
  SetFormStep,
  SetFormStepperStatus,
  SetFunctionalCategories,
  SetGeneralInfo,
  SetProjects,
  SubmitApplication,
  AutosaveGeneralInfo,
  ResetAutosaveState,
  AutosaveFunctionalCategories,
  AutosaveProjects,
  SetDraftApplicationCount,
} from '../actions/application-draft.action';

import { of, tap } from 'rxjs';
import { AutosaveStatus, FormSequence, FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { CurrentContextState } from './current-context.state';
import { SetCurrentApplicationDraftId } from '../actions/current-context.action';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';

const applicationRecordDefaultData: ApplicationDraftRecordDto = {
  areThereOtherSourcesOfFunding: undefined,
  contactFirstName: '',
  contactLastName: '',
  contactPhoneNumber: '',
  contactEmailAddress: '',
  description: '',
  functionalCategories: [],
  fundingFromOther: '',
  isFundingFromAMWWP: false,
  isFundingFromCCBF: false,
  isFundingFromSTIP: false,
  isFundingFromMSI: false,
  isFundingFromWaterForLife: false,
  isFundingFromOther: false,
  isOwnedByLocalGovernment: false,
  name: '',
  primaryOutcomeId: 0,
  projects: [],
  localGovernmentOwnership: '',
  nonProfitOwnership: '',
  regionalServiceCommissionOwnership: '',
};

export class AutoSaveState {
  callsInProgress: number;
  status: AutosaveStatus;
}

export enum EditApplicationType {
  Draft = 'DRAFT',
  Returned = 'RETURNED',
}

export class ApplicationDraftStateModel {
  applicationType?: EditApplicationType;
  applicationDraft?: ApplicationDraftDto;
  applicationDraftId?: number;
  formStepperStatus: {
    [FormSequence.GeneralInfo]: FormStatusCodes;
    [FormSequence.FunctionalCat]: FormStatusCodes;
    [FormSequence.Proj]: FormStatusCodes;
    [FormSequence.Cert]: FormStatusCodes;
  };
  formStep: FormSequence;
  existingDraft: boolean;
  onGoingApiCall: boolean | undefined;
  apiCallSuccess: boolean | undefined;
  autosaveStatus: AutoSaveState;
  draftApplicationCount: number;
}

@State<ApplicationDraftStateModel>({
  name: 'applicationgrant',
  defaults: {
    applicationType: undefined,
    applicationDraft: {
      applicationRecord: applicationRecordDefaultData,
      id: 0,
      createdAt: '',
      createdBy: '',
      createdByName: '',
      updatedAt: '',
      updatedBy: '',
      updatedByName: '',
    },
    applicationDraftId: undefined,
    formStepperStatus: {
      [FormSequence.GeneralInfo]: FormStatusCodes.NotStarted,
      [FormSequence.FunctionalCat]: FormStatusCodes.NotStarted,
      [FormSequence.Proj]: FormStatusCodes.NotStarted,
      [FormSequence.Cert]: FormStatusCodes.NotStarted,
    },
    formStep: FormSequence.GeneralInfo,
    existingDraft: false,
    onGoingApiCall: undefined,
    apiCallSuccess: undefined,
    autosaveStatus: { callsInProgress: 0, status: AutosaveStatus.idle },
    draftApplicationCount: 0,
  },
})
@Injectable()
export class ApplicationGrantState {
  constructor(
    private applicationService: ApplicationExtService,
    private store: Store,
    private snackBarService: SnackBarService,
  ) {
    this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
      this.currentOrganizationId = currentOrganizationId;
    });
  }
  currentOrganizationId: number;

  @Selector()
  static getApplicationType(state: ApplicationDraftStateModel) {
    return state.applicationType;
  }

  @Selector()
  static fetchApplicationDto(state: ApplicationDraftStateModel) {
    return state.applicationDraft?.applicationRecord;
  }

  @Selector()
  static fetchProjects(state: ApplicationDraftStateModel) {
    return state.applicationDraft?.applicationRecord.projects;
  }

  @Selector()
  static getApplicationDraftId(state: ApplicationDraftStateModel) {
    return state.applicationDraftId;
  }
  @Selector()
  static existingApplicationDraft(state: ApplicationDraftStateModel) {
    return state.existingDraft;
  }

  @Selector()
  static getFormStepperStatus(state: ApplicationDraftStateModel) {
    return state.formStepperStatus;
  }

  @Selector()
  static getFormStep(state: ApplicationDraftStateModel) {
    return state.formStep;
  }

  @Selector()
  static getApiCallSucceded(state: ApplicationDraftStateModel) {
    return state.apiCallSuccess;
  }

  @Selector()
  static getApiCallCompleted(state: ApplicationDraftStateModel) {
    return state.onGoingApiCall;
  }

  @Selector()
  static getAutosaveStatus(state: ApplicationDraftStateModel) {
    return state.autosaveStatus.status;
  }

  @Action(FetchApplicationDraft)
  fetchApplicationDraft(
    { setState }: StateContext<ApplicationDraftStateModel>,
    { applicationDraftId }: FetchApplicationDraft,
  ) {
    return this.applicationService
      .findOneDraft({ id: applicationDraftId, organizationId: this.currentOrganizationId })
      .pipe(
        tap((application: ApplicationDraftDto) => {
          setState(
            patch({
              applicationType: application.parentApplicationId
                ? EditApplicationType.Returned
                : EditApplicationType.Draft,
              applicationDraft: patch({ applicationRecord: application.applicationRecord }),
            }),
          );
        }),
      );
  }

  @Action(SetApplicationDraftId)
  setApplicationDraftId(
    { setState, dispatch }: StateContext<ApplicationDraftStateModel>,
    { applicationDraftId }: SetApplicationDraftId,
  ) {
    dispatch(new SetCurrentApplicationDraftId(applicationDraftId));
    setState(patch({ applicationDraftId, existingDraft: applicationDraftId ? true : false }));
    if (applicationDraftId) {
      dispatch(new FetchApplicationDraft(applicationDraftId));
    } else {
      setState(
        patch({
          applicationDraft: patch({ applicationRecord: applicationRecordDefaultData }),
          applicationDraftId: 0,
          applicationType: EditApplicationType.Draft,
        }),
      );
      dispatch(new SetFormStep(FormSequence.GeneralInfo));
      dispatch(
        new SetFormStepperStatus({
          [FormSequence.GeneralInfo]: FormStatusCodes.NotStarted,
          [FormSequence.FunctionalCat]: FormStatusCodes.NotStarted,
          [FormSequence.Proj]: FormStatusCodes.NotStarted,
          [FormSequence.Cert]: FormStatusCodes.NotStarted,
        }),
      );
    }
  }

  @Action(DeleteApplicationDraft)
  deleteApplicationDraft(
    { setState }: StateContext<ApplicationDraftStateModel>,
    { applicationDraftId }: DeleteApplicationDraft,
  ) {
    console.log('DeleteApplicationDraft curDrafeId=' + applicationDraftId);
    // reset memory state
    setState(
      patch({
        applicationDraft: {
          applicationRecord: applicationRecordDefaultData,
          id: 0,
          createdAt: '',
          createdBy: '',
          createdByName: '',
          updatedAt: '',
          updatedBy: '',
          updatedByName: '',
        },
        applicationDraftId: 0,
        existingDraft: false,
      }),
    );

    // delete draft in backend
    return this.applicationService.removeDraft({ organizationId: this.currentOrganizationId, id: applicationDraftId });
  }

  @Action(SetFormStepperStatus)
  setFormStepperStatus(
    { getState, setState }: StateContext<ApplicationDraftStateModel>,
    { formStepperStatus }: SetFormStepperStatus,
  ) {
    console.log('Form Stepper Status [State]', formStepperStatus);
    setState(
      patch({
        formStepperStatus: {
          ...getState().formStepperStatus,
          ...formStepperStatus,
        },
      }),
    );
  }

  @Action(SetFormStep)
  setFormStep({ getState, setState }: StateContext<ApplicationDraftStateModel>, { formStep }: SetFormStep) {
    console.log('Form Step [State]', formStep);
    setState(patch({ formStep: formStep }));
    console.log('Current State after change in Form Step', getState());
  }

  @Action(SetGeneralInfo)
  setGeneralInfo({ getState, setState }: StateContext<ApplicationDraftStateModel>, { generalInfo }: SetGeneralInfo) {
    if (getState().applicationDraft && getState().applicationDraft?.applicationRecord) {
      setState(
        patch({
          applicationDraft: patch({
            applicationRecord: patch({
              primaryOutcomeId: generalInfo.primaryOutcomeId,
              description: generalInfo.description,
              name: generalInfo.name,
              contactFirstName: generalInfo.contactFirstName,
              contactLastName: generalInfo.contactLastName,
              contactEmailAddress: generalInfo.contactEmailAddress,
              contactPhoneNumber: generalInfo.contactPhoneNumber,
            }),
          }),
        }),
      );
    } else {
      const newAppDraft: ApplicationDraftDto = {
        applicationRecord: {
          primaryOutcomeId: generalInfo.primaryOutcomeId,
          description: generalInfo.description,
          name: generalInfo.name!,
          contactFirstName: generalInfo.contactFirstName,
          contactLastName: generalInfo.contactLastName,
          contactEmailAddress: generalInfo.contactEmailAddress,
          contactPhoneNumber: generalInfo.contactPhoneNumber,
          projects: [],
          functionalCategories: [],
        },
        id: 0,
        createdAt: '',
        createdBy: '',
        createdByName: '',
        updatedAt: '',
        updatedBy: '',
        updatedByName: '',
      };
      setState(
        patch({
          applicationDraft: newAppDraft,
        }),
      );
    }
  }

  @Action(SetFunctionalCategories)
  setFunctionalCategories(
    { setState }: StateContext<ApplicationDraftStateModel>,
    { functionalCategories }: SetFunctionalCategories,
  ) {
    console.log('[State]Set Functional categories handler', functionalCategories);
    setState(
      patch({
        applicationDraft: patch({
          applicationRecord: patch({
            functionalCategories: functionalCategories?.functionalCategories,

            areThereOtherSourcesOfFunding: functionalCategories?.areThereOtherSourcesOfFunding,
            isFundingFromAMWWP: functionalCategories?.isFundingFromAMWWP,
            isFundingFromCCBF: functionalCategories?.isFundingFromCCBF,
            isFundingFromSTIP: functionalCategories?.isFundingFromSTIP,
            isFundingFromMSI: functionalCategories?.isFundingFromMSI,
            isFundingFromWaterForLife: functionalCategories?.isFundingFromWaterForLife,
            isFundingFromOther: functionalCategories?.isFundingFromOther,
            fundingFromOther: functionalCategories?.fundingFromOther,

            isOwnedByLocalGovernment: functionalCategories?.isOwnedByLocalGovernment ?? false,
            isNonProfitOwnership: functionalCategories?.isNonProfitOwnership,
            nonProfitOwnership: functionalCategories?.nonProfitOwnership,
            isRegionalServiceCommissionOwnership: functionalCategories?.isRegionalServiceCommissionOwnership,
            regionalServiceCommissionOwnership: functionalCategories?.regionalServiceCommissionOwnership,
            isLocalGovernmentOwnership: functionalCategories?.isLocalGovernmentOwnership,
            localGovernmentOwnership: functionalCategories?.localGovernmentOwnership,
          }),
        }),
      }),
    );
  }

  @Action(SetProjects)
  setProjects({ setState }: StateContext<ApplicationDraftStateModel>, { projectRecords }: SetProjects) {
    console.log('[SetProjects]', projectRecords);
    let projects: ProjectDraftDto[] = [];
    for (const project of projectRecords) {
      const anticipatedStartDate =
        project.anticipatedStartDate != undefined ? new Date(project.anticipatedStartDate).toISOString() : undefined;
      const anticipatedEndDate =
        project.anticipatedEndDate != undefined ? new Date(project.anticipatedEndDate).toISOString() : undefined;
      projects = [
        ...projects,
        {
          name: project.projectName,
          description: project.description,
          capitalAssetTypeId: project.primaryCapitalAssetId,
          additionalCapitalAssetTypeIds: project.additionalCapitalAssetIds,
          address: project.address,
          amountFromOtherGrantPrograms: project.fundingFromOtherGrantPrograms,
          amountRequestedFromLGFF: project.lgffFundingAmountRequested,
          anticipatedStartDate,
          anticipatedEndDate,
          estimatedTotalCost: project.estimatedTotalCost,
          totalAmountFromMunicipalSources: project.fundingFromMunicipalSources,
          latitude: project.latitude,
          longitude: project.longitude,
          projectType: (project.projectType as ApplicationProjectType) || undefined,
          quantityNew: project.projectType == ApplicationProjectType.Upgrade ? undefined : project.quantityNew,
          quantityUpgrade: project.projectType == ApplicationProjectType.New ? undefined : project.quantityUpgrade,
          currentAssetConditionRankingId:
            project.projectType == ApplicationProjectType.New ? undefined : project.currentAssetRankingId,
        },
      ];
    }
    setState(
      patch({
        applicationDraft: patch({
          applicationRecord: patch({
            projects,
          }),
        }),
      }),
    );
  }

  @Action(DeleteProjects)
  deleteProjects({ setState }: StateContext<ApplicationDraftStateModel>) {
    setState(
      patch({
        applicationDraft: patch({
          applicationRecord: patch({
            projects: [],
          }),
        }),
      }),
    );
  }

  @Action(SaveApplicationDraft)
  saveApplicationDraft({ getState, setState, dispatch }: StateContext<ApplicationDraftStateModel>) {
    if (getState().applicationDraftId) {
      //setState(patch({ applicationDraft: patch({ applicationDraftId: getState().applicationDraftId }) }));
      const payload = {
        organizationId: this.currentOrganizationId,
        id: getState().applicationDraftId!,
        body: { ...getState().applicationDraft },
      };
      this.applicationService.updateDraft(payload).subscribe({
        next: () => {
          console.log('Draft updated!');
          setState(
            patch({
              formStepperStatus: {
                ...getState().formStepperStatus,
                [getState().formStep]: FormStatusCodes.Complete,
              },

              formStep:
                getState().formStep >= 1 && getState().formStep < 4 ? getState().formStep + 1 : getState().formStep,
            }),
          );
          console.log('[SaveApplicationDraft] Current State after save [update]', getState());
        },
        error: (err) => {
          console.error(err);
        },
      });
    } else {
      const payload = {
        organizationId: this.currentOrganizationId,
        body: { ...getState().applicationDraft } as CreateApplicationDraftDto,
      };
      console.log('Create API service payload', payload);
      this.applicationService.createDraft(payload).subscribe({
        next: (data: ApplicationDraftDto) => {
          console.log('Draft Saved!', data);
          setState(
            patch({
              applicationDraftId: data.id,
              formStepperStatus: {
                ...getState().formStepperStatus,
                [getState().formStep]: FormStatusCodes.Complete,
              },
              formStep:
                getState().formStep >= 1 && getState().formStep < 4 ? getState().formStep + 1 : getState().formStep,
            }),
          );
          console.log('[SaveApplicationDraft] Current State after save [create]', getState());
          dispatch(new SetCurrentApplicationDraftId(data.id));
        },
        error: (err) => {
          console.error(err);
        },
      });
    }
  }

  @Action(AutoSaveApplicationDraft)
  autoSaveApplicationDraft({ getState, setState, dispatch }: StateContext<ApplicationDraftStateModel>) {
    if (getState().applicationDraftId) {
      const payload = {
        organizationId: this.currentOrganizationId,
        id: getState().applicationDraftId!,
        body: { ...getState().applicationDraft },
      };
      this.applicationService.updateDraft(payload).subscribe({
        next: () => {
          console.log('Draft updated!');
          setState(
            patch({
              apiCallSuccess: true,
              onGoingApiCall: false,
            }),
          );
          console.log('[AutoSaveApplicationDraft] Current State after save [update]', getState());
        },
        error: (err) => {
          console.error('Error occurred [state]', err);
          setState(
            patch({
              apiCallSuccess: false,
              onGoingApiCall: false,
            }),
          );
          throw err;
        },
      });
    } else {
      const payload = {
        organizationId: this.currentOrganizationId,
        body: getState().applicationDraft as CreateApplicationDraftDto,
      };
      console.log('Create API service payload', payload);
      this.applicationService.createDraft(payload).subscribe({
        next: (data: ApplicationDraftDto) => {
          console.log('Draft Saved!', data);
          setState(
            patch({
              applicationDraftId: data.id,
              apiCallSuccess: true,
              onGoingApiCall: false,
            }),
          );
          console.log('[AutoSaveApplicationDraft] Current State after save [create]', getState());
          dispatch(new SetCurrentApplicationDraftId(data.id));
        },
        error: (err) => {
          setState(
            patch({
              apiCallSuccess: false,
              onGoingApiCall: false,
            }),
          );
          console.error('Error occurred [state]', err);
          throw err;
        },
      });
    }
  }

  @Action(AutosaveGeneralInfo)
  autosaveGeneralInfo(ctx: StateContext<ApplicationDraftStateModel>, { generalInfo }: AutosaveGeneralInfo) {
    const draft = { ...ctx.getState().applicationDraft };
    const applicationRecord: ApplicationDraftRecordDto = {
      ...draft.applicationRecord,
      primaryOutcomeId: generalInfo.primaryOutcomeId,
      description: generalInfo.description,
      name: generalInfo.name!,
      contactFirstName: generalInfo.contactFirstName,
      contactLastName: generalInfo.contactLastName,
      contactEmailAddress: generalInfo.contactEmailAddress,
      contactPhoneNumber: generalInfo.contactPhoneNumber,
      functionalCategories: draft.applicationRecord?.functionalCategories ?? [],
      projects: draft.applicationRecord?.projects ?? [],
    };

    this._autosave(applicationRecord, ctx);
  }

  @Action(AutosaveFunctionalCategories)
  autosaveFunctionalCategories(
    ctx: StateContext<ApplicationDraftStateModel>,
    { payload }: AutosaveFunctionalCategories,
  ) {
    const draft = { ...ctx.getState().applicationDraft };
    const applicationRecord: ApplicationDraftRecordDto = {
      ...draft.applicationRecord,
      name: draft.applicationRecord?.name ?? '',

      areThereOtherSourcesOfFunding: payload.functionalCategoryPage.areThereOtherSourcesOfFunding,
      isFundingFromAMWWP: payload.functionalCategoryPage.isFundingFromAMWWP,
      isFundingFromCCBF: payload.functionalCategoryPage.isFundingFromCCBF,
      isFundingFromSTIP: payload.functionalCategoryPage.isFundingFromSTIP,
      isFundingFromMSI: payload.functionalCategoryPage.isFundingFromMSI,
      isFundingFromWaterForLife: payload.functionalCategoryPage.isFundingFromWaterForLife,
      isFundingFromOther: payload.functionalCategoryPage.isFundingFromOther,
      fundingFromOther: payload.functionalCategoryPage.fundingFromOther,

      isOwnedByLocalGovernment: payload.functionalCategoryPage.isOwnedByLocalGovernment ?? false,
      isNonProfitOwnership: payload.functionalCategoryPage.isNonProfitOwnership,
      nonProfitOwnership: payload.functionalCategoryPage.nonProfitOwnership,
      isRegionalServiceCommissionOwnership: payload.functionalCategoryPage.isRegionalServiceCommissionOwnership,
      regionalServiceCommissionOwnership: payload.functionalCategoryPage.regionalServiceCommissionOwnership,
      isLocalGovernmentOwnership: payload.functionalCategoryPage.isLocalGovernmentOwnership,
      localGovernmentOwnership: payload.functionalCategoryPage.localGovernmentOwnership,

      functionalCategories: payload.functionalCategoryPage.functionalCategories ?? [],
      projects: draft.applicationRecord?.projects ?? [],
    };

    this._autosave(applicationRecord, ctx, {
      operation: payload.operation,
      functionCategory: payload.functionalCategory,
    });
  }

  @Action(AutosaveProjects)
  autosaveProjects(ctx: StateContext<ApplicationDraftStateModel>, { payload }: AutosaveProjects) {
    let projectDtos: ProjectDraftDto[] = [];
    for (const project of payload.projects) {
      const anticipatedStartDate =
        project.anticipatedStartDate != undefined ? new Date(project.anticipatedStartDate).toISOString() : undefined;
      const anticipatedEndDate =
        project.anticipatedEndDate != undefined ? new Date(project.anticipatedEndDate).toISOString() : undefined;
      projectDtos = [
        ...projectDtos,
        {
          name: project.projectName,
          description: project.description,
          capitalAssetTypeId: project.primaryCapitalAssetId,
          additionalCapitalAssetTypeIds: project.additionalCapitalAssetIds,
          address: project.address,
          amountFromOtherGrantPrograms: project.fundingFromOtherGrantPrograms,
          amountRequestedFromLGFF: project.lgffFundingAmountRequested,
          anticipatedStartDate,
          anticipatedEndDate,
          estimatedTotalCost: project.estimatedTotalCost,
          totalAmountFromMunicipalSources: project.fundingFromMunicipalSources,
          latitude: project.latitude,
          longitude: project.longitude,
          projectType: (project.projectType as ApplicationProjectType) || undefined,
          quantityNew: project.projectType == ApplicationProjectType.Upgrade ? undefined : project.quantityNew,
          quantityUpgrade: project.projectType == ApplicationProjectType.New ? undefined : project.quantityUpgrade,
          currentAssetConditionRankingId:
            project.projectType == ApplicationProjectType.New ? undefined : project.currentAssetRankingId,
        },
      ];
    }
    const draft = { ...ctx.getState().applicationDraft };
    const applicationRecord: ApplicationDraftRecordDto = {
      ...draft.applicationRecord,
      name: draft.applicationRecord?.name ?? '',
      functionalCategories: draft.applicationRecord?.functionalCategories ?? [],
      projects: projectDtos,
    };

    this._autosave(applicationRecord, ctx, { operation: payload?.operation, projectName: payload?.projectName });
  }

  private _autosave(
    applicationRecord: ApplicationDraftRecordDto,
    { getState, setState, dispatch }: StateContext<ApplicationDraftStateModel>,
    autoSaveDetails?: { operation?: string; projectName?: string; functionCategory?: string },
  ) {
    const draft = { ...getState().applicationDraft, applicationRecord };
    setState(
      patch({
        autosaveStatus: {
          callsInProgress: getState().autosaveStatus.callsInProgress + 1,
          status: AutosaveStatus.inProgress,
        },
      }),
    );

    if (getState().applicationDraftId) {
      const payload = {
        organizationId: this.currentOrganizationId,
        id: getState().applicationDraftId!,
        body: { ...draft },
      };
      this.applicationService.updateDraft(payload).subscribe({
        next: () => {
          const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
          setState(
            patch({
              autosaveStatus: {
                callsInProgress: callsInProgress,
                status: callsInProgress === 0 ? AutosaveStatus.success : AutosaveStatus.inProgress,
              },
            }),
          );
          if (autoSaveDetails && autoSaveDetails.operation === 'add' && autoSaveDetails.projectName) {
            this.snackBarService.showSuccessMessageWithScrollToTopLink(
              `"${autoSaveDetails.projectName}" project successfully added.`,
              'project-heading-h1',
              'to add another project.',
            );
          }
          if (autoSaveDetails && autoSaveDetails.operation === 'update' && autoSaveDetails.projectName) {
            this.snackBarService.showSuccessMessage(`"${autoSaveDetails.projectName}" project successfully edited.`);
          }
          if (autoSaveDetails && autoSaveDetails.operation === 'delete' && autoSaveDetails.projectName) {
            this.snackBarService.showSuccessMessage(`"${autoSaveDetails.projectName}" project successfully deleted.`);
          }
          if (autoSaveDetails && autoSaveDetails.operation === 'delete' && autoSaveDetails.functionCategory) {
            this.snackBarService.showSuccessMessage(
              `"${autoSaveDetails.functionCategory}" category successfully deleted.`,
            );
          }
          if (autoSaveDetails && autoSaveDetails.operation === 'update' && autoSaveDetails.functionCategory) {
            this.snackBarService.showSuccessMessage(
              `"${autoSaveDetails.functionCategory}" category successfully edited.`,
            );
          }
          if (autoSaveDetails && autoSaveDetails.operation === 'add' && autoSaveDetails.functionCategory) {
            this.snackBarService.showSuccessMessageWithScrollToTopLink(
              `"${autoSaveDetails.functionCategory}" category successfully added.`,
              'func-cat-heading-1',
              'to add another category.',
            );
          }
        },
        error: (err) => {
          console.error('Error occurred [state]', err);
          const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
          setState(
            patch({
              autosaveStatus: {
                callsInProgress: callsInProgress,
                status: callsInProgress === 0 ? AutosaveStatus.failure : AutosaveStatus.inProgress,
              },
            }),
          );
        },
      });
    } else {
      const payload = {
        organizationId: this.currentOrganizationId,
        body: { ...draft } as CreateApplicationDraftDto,
      };
      this.applicationService.createDraft(payload).subscribe({
        next: (data: ApplicationDraftDto) => {
          const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
          setState(
            patch({
              applicationDraftId: data.id,
              autosaveStatus: {
                callsInProgress: callsInProgress,
                status: callsInProgress === 0 ? AutosaveStatus.success : AutosaveStatus.inProgress,
              },
            }),
          );
          dispatch(new SetCurrentApplicationDraftId(data.id));
        },
        error: (err) => {
          console.error('Error occurred [state]', err);
          const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
          setState(
            patch({
              autosaveStatus: {
                callsInProgress: callsInProgress,
                status: callsInProgress === 0 ? AutosaveStatus.failure : AutosaveStatus.inProgress,
              },
            }),
          );
        },
      });
    }
  }

  @Action(ResetAutosaveState)
  resetAutosaveState({ setState }: StateContext<ApplicationDraftStateModel>) {
    setState(
      patch({
        autosaveStatus: {
          callsInProgress: 0,
          status: AutosaveStatus.idle,
        },
      }),
    );
  }

  @Action(SubmitApplication)
  submitApplication({ getState }: StateContext<ApplicationDraftStateModel>, { certifiedByRole }: SubmitApplication) {
    if (getState().applicationDraftId) {
      return this.applicationService.submitApplication({
        organizationId: this.currentOrganizationId,
        draftId: getState().applicationDraftId!,
        certifiedByRole,
      });
    } else {
      //we do nothing
      return of(`Bad request`);
    }
  }

  @Action(SetDraftApplicationCount)
  setDraftApplicationCount(
    { setState }: StateContext<ApplicationDraftStateModel>,
    { draftApplicationCount }: SetDraftApplicationCount,
  ) {
    console.log('Draft application count [State]', draftApplicationCount);
    setState(
      patch({
        draftApplicationCount,
      }),
    );
  }

  @Selector()
  static getDraftApplicationCount({ draftApplicationCount }: ApplicationDraftStateModel) {
    return draftApplicationCount;
  }

  private _getFormattedDate(dateString: string | undefined): string | undefined {
    if (!dateString) {
      return undefined;
    }
    const date = new Date(dateString);
    if (date.toString() === 'Invalid Date') {
      return undefined;
    }
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  }
}
