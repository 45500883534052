/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddSepoToBatchDto } from '../models/add-sepo-to-batch-dto';
import { CreateSepoBatchDto } from '../models/create-sepo-batch-dto';
import { SepoBatchDto } from '../models/sepo-batch-dto';
import { SepoBatchListDto } from '../models/sepo-batch-list-dto';
import { SepoBatchListFilter } from '../models/sepo-batch-list-filter';

@Injectable({ providedIn: 'root' })
export class SepoBatchService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/api/sepo-batch/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(
    params: {
      body: CreateSepoBatchDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SepoBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.CreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SepoBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(
    params: {
      body: CreateSepoBatchDto
    },
    context?: HttpContext
  ): Observable<SepoBatchDto> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<SepoBatchDto>): SepoBatchDto => r.body)
    );
  }

  /** Path part for operation `exists()` */
  static readonly ExistsPath = '/api/sepo-batch/exists/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exists()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists$Response(
    params: {
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.ExistsPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists(
    params: {
      name: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.exists$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `count_1()` */
  static readonly Count_1Path = '/api/sepo-batch/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count$Response(
    params: {
      body: SepoBatchListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.Count_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count(
    params: {
      body: SepoBatchListFilter
    },
    context?: HttpContext
  ): Observable<number> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAll_2()` */
  static readonly FindAll_2Path = '/api/sepo-batch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll$Response(
    params: {
      take?: number;
      skip?: number;
      body: SepoBatchListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SepoBatchListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.FindAll_2Path, 'post');
    if (params) {
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SepoBatchListDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll(
    params: {
      take?: number;
      skip?: number;
      body: SepoBatchListFilter
    },
    context?: HttpContext
  ): Observable<Array<SepoBatchListDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SepoBatchListDto>>): Array<SepoBatchListDto> => r.body)
    );
  }

  /** Path part for operation `addSeposToBatch()` */
  static readonly AddSeposToBatchPath = '/api/sepo-batch/add-to-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSeposToBatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSeposToBatch$Response(
    params: {
      id: number;
      body: AddSepoToBatchDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.AddSeposToBatchPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addSeposToBatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSeposToBatch(
    params: {
      id: number;
      body: AddSepoToBatchDto
    },
    context?: HttpContext
  ): Observable<number> {
    return this.addSeposToBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `sepoBatchControllerFindOne()` */
  static readonly SepoBatchControllerFindOnePath = '/api/sepo-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sepoBatchControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.SepoBatchControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sepoBatchControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerFindOne(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sepoBatchControllerFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sepoBatchControllerRemove()` */
  static readonly SepoBatchControllerRemovePath = '/api/sepo-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sepoBatchControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerRemove$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.SepoBatchControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sepoBatchControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sepoBatchControllerRemove(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sepoBatchControllerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sepoBatchControllerUpdate()` */
  static readonly SepoBatchControllerUpdatePath = '/api/sepo-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sepoBatchControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sepoBatchControllerUpdate$Response(
    params: {
      id: string;
      body: SepoBatchDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SepoBatchService.SepoBatchControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sepoBatchControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sepoBatchControllerUpdate(
    params: {
      id: string;
      body: SepoBatchDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sepoBatchControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
