import { FormControl } from '@angular/forms';

export const ValidationPatterns = {
  nameAlphabeticSpaceDash: /(^[a-zA-Z]+[a-zA-Z \-\u0020]*[a-zA-Z]+$)|(^[a-zA-Z]+$)/,
  nameAlphabeticNumberSpaceDash: /(^[a-zA-Z]+[a-zA-Z0-9 \-\u0020]*[a-zA-Z0-9]+$)|(^[a-zA-Z]+$)/,
  GoaDate: /(^[0-9]{4}-[0-9]{2}-[0-9]{2}$)|(^[0-9]{2}-[0-9]{2}-[0-9]{4}$)/,
  numberPure: /^[0-9]*$/,
  numberPure1: /^[1-9][0-9]*$/,
  Phone10Digital: /^[0-9]{3}[.-][0-9]{3}[.-][0-9]{4}$/,
  NorthAmericaPhone: /^[(]?[0-9]{3}[) -]?[0-9]{3}[ -]?[0-9]{4}$/,
  GoaPhone: /^[1-9][0-9]{2}[-]?[0-9]{3}[-]?[0-9]{4}$/,
  email: /^[a-zA-Z0-9.!#$%&’"*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
};

// field UI and validation setting/config

export const UiFieldDefaultMaxLength = 50;
export const DateOnOrLaterThanSysStartDate = 'DateOnOrLaterThanSysStartDate';
export const DateOnOrLaterThanSpecified = 'DateOnOrLaterThanSpecified';

export interface UiFieldDef {
  name: string; // mandatory, for angular + html
  id?: string; // = name if not overwrite, for html unique id
  idWrap?: string; // = id + '-wrap' if not overwrite, for error-jump/focus-to Goa-component-wrap
  labelText: string; // mandatory
  helpTextRaw?: string; // optional, help-text/tip define
  placeHolderRaw?: string; // optional, place holder

  nameCtr: string; // form control name, can be same as name, unless code used some other name (keep old name to avoid broken existing code)

  errorRequired?: string; // Required-error string, if this field is "required"
  isRequired?: boolean; // generated from errorRequired?.length > 0

  patternRule?: string; // key from ValidationPatterns[]
  errorPattern?: string; // pattern-error string, empty=errorRequired if patternRule?.length > 0
  isPattern?: boolean; // generated from patternRule?.length > 0 && errorPattern?.length > 0

  maxLength?: number; // applied if maxLength > 0
  //minLength?: number,     // not needed so far;
  errorMaxLength?: string;

  errorMinValue?: string; // not needed so far, error for 3 set:
  minValue?: number | string | Date; // will replace by below 3:
  // minString?: string,
  // minDate?: string,
  // minNumber?: number,

  //errorMaxValue?: string, // not needed so far
  //maxValue?: number | string | Date,  // to 3: minString, minDate, minNumber= ref-data

  errorMinimumDate?: string;
  minimumDateRule?: string; // DateOnOrLaterThanSysStartDate, DateOnOrLaterThanSpecified, or more rules to be added
  minimumDateSpecified?: string; // e.g. 'Jan 1, 2023'; for DateOnOrLaterThanSpecified
  isMinimumDate?: boolean; // generated from minimumDateRule and minimumDateSpecified and errorMinimumDate
  minDateValue?: Date; // generated from  minimumDateRule and minimumDateSpecified

  errorCompareDateLessThan?: string;
  dateLessThanField?: string;

  errorCompareDateGreaterThan?: string;
  dateGreaterThanField?: string;
}

// field UI + html def + form-control + error-msg-array + translated static labels
export interface UiFieldCtrDef extends UiFieldDef {
  helptext: string; // undefined will convert to ''
  placeholder: string; // undefined will convert to ''
  formCtr: FormControl;
  focusedInNonBlankOrErrorField?: boolean;
  focusedOutFieldByTrueBlurEvent?: boolean;
  errorMsg?: string[];
}

export const PageIds = {
  app: {
    general: {
      firstName: {
        name: 'app-general-first-name',
        nameCtr: 'formCtrFirstName',
        labelText: 'First name',
        // helpTextRaw: 'Enter a valid first name here.',
        // placeHolderRaw: 'First name',
        errorRequired: 'Enter a first name.',
        // patternRule: 'nameAlphabeticNumberSpaceDash',
        maxLength: 63,
      },
      lastName: {
        name: 'app-general-last-name',
        nameCtr: 'formCtrLastName',
        labelText: 'Last name',
        errorRequired: 'Enter a last name.',
        maxLength: 63,
      },
      phone: {
        name: 'app-general-phone',
        nameCtr: 'formCtrPhone',
        labelText: 'Phone number',
        errorRequired: 'Enter a valid 10 digit phone number.',
        patternRule: 'GoaPhone',
        maxLength: 12,
        // placeHolderRaw: '222-333-4567',
        // helpTextRaw: 'example: 123-456-7890',
      },
      email: {
        name: 'app-general-email',
        nameCtr: 'formCtrEmail',
        labelText: 'Email address',
        errorRequired: 'Enter a valid email address (e.g., myname@mycity.ca).',
        patternRule: 'email',
        maxLength: 63,
      },
      applicationName: {
        name: 'app-general-application-name',
        nameCtr: 'formCtrApplicationName',
        labelText: 'Application name',
        errorRequired: 'Enter an application name.',
        helpTextRaw:
          'Create a name that represents the project or group of projects being submitted under this application.',
      },
      primaryOutcome: {
        name: 'app-general-primary-outcome-drop-down',
        nameCtr: 'formCtrPrimaryOutcome',
        labelText: 'Primary outcome',
        errorRequired: 'Select a primary outcome.',
        placeHolderRaw: 'Select an outcome',
        helpTextRaw: 'Select the applicable primary outcome for the project(s) submitted under this application.',
      },
      description: {
        name: 'app-general-description',
        nameCtr: 'formCtrDescription',
        labelText: 'Description',
        errorRequired: 'Enter a description.',
        maxLength: 400,
        helpTextRaw: 'Provide details to determine eligibility, including capital asset(s) and activities.',
      },
    },
    project: {
      projectName: {
        name: 'app-project-project-name',
        nameCtr: 'projectName',
        labelText: 'Project name',
        helpTextRaw:
          'Project name should include a unique identifier, such as asset information, location or building name.',
        errorRequired: 'Enter a project name.',
        errorMaxLength: 'Max length exceeded',
        maxLength: 75,
        // patternRule: 'nameAlphabeticNumberSpaceDash',
      },
      projectDescription: {
        name: 'app-project-project-description',
        nameCtr: 'projectDescription',
        labelText: 'Brief project description',
        helpTextRaw: 'Provide a brief description of assets and/or activities being funded by LGFF.',
        errorRequired: 'Enter a project description.',
        errorMaxLength: 'Max length exceeded',
        maxLength: 250,
      },
      primaryCapitalAsset: {
        name: 'app-project-primary-capital-asset',
        nameCtr: 'primaryCapitalAsset',
        labelText: 'Primary capital asset',
        helpTextRaw: 'Select the primary asset being constructed or upgraded for this project.',
        placeHolderRaw: 'Please select an asset',
        errorRequired: 'Please select an asset.',
      },
      additionalCapitalAssets: {
        name: 'app-project-additional-capital-assets',
        nameCtr: 'additionalCapitalAssets',
        labelText: 'Additional capital asset(s)',
        helpTextRaw: 'Select any other capital assets being funded for this project.',
        placeHolderRaw: 'Please select one or more assets',
      },
      projectType: {
        name: 'app-project-project-type',
        nameCtr: 'projectType',
        labelText: 'Project type',
        helpTextRaw: 'Indicate if this is a new and/or upgraded asset.',
        placeHolderRaw: 'Please select a type',
        errorRequired: 'Select a type.',
      },
      quantityNew: {
        name: 'app-project-quantity-new',
        nameCtr: 'quantityNew',
        labelText: 'Quantity: new',
        helpTextRaw: 'Indicate the quantity of new infrastructure in this project.',
        errorRequired: 'Enter the quantity for the new asset.',
      },
      quantityUpgrade: {
        name: 'app-project-quantity-upgrade',
        nameCtr: 'quantityUpgrade',
        labelText: 'Quantity: upgrade',
        helpTextRaw: 'Indicate the quantity of infrastructure upgrades in this project.',
        errorRequired: 'Enter the quantity for asset to be upgraded.',
      },
      currentAssetRanking: {
        name: 'app-project-current-asset-ranking',
        nameCtr: 'currentAssetRanking',
        labelText: 'Current condition ranking of asset to be upgraded',
        placeHolderRaw: 'Please select a ranking',
        errorRequired: 'Select a ranking.',
      },
      anticipatedStartDate: {
        name: 'app-project-anticipated-start-date',
        nameCtr: 'anticipatedStartDate',
        labelText: 'Anticipated start date',
        errorRequired: 'Choose a start date.',
        patternRule: 'GoaDate',
        errorMinimumDate: 'Start date cannot be earlier than lgff system start date.',
        minimumDateRule: DateOnOrLaterThanSysStartDate,
        errorCompareDateLessThan: 'Start date cannot be after the end date.',
        dateLessThanField: 'anticipatedEndDate',
      },
      anticipatedEndDate: {
        name: 'app-project-anticipated-end-date',
        nameCtr: 'anticipatedEndDate',
        labelText: 'Anticipated end date',
        errorRequired: 'Choose an end date.',
        patternRule: 'GoaDate',
        errorMinimumDate: 'End date cannot be earlier than lgff system start date.',
        minimumDateRule: DateOnOrLaterThanSysStartDate,
        errorCompareDateGreaterThan: 'End date cannot be earlier than the start date.',
        dateGreaterThanField: 'anticipatedStartDate',
      },
      estimatedTotalCost: {
        name: 'app-project-estimated-total-cost',
        nameCtr: 'estimatedTotalCost',
        labelText: 'Estimated total cost',
        helpTextRaw: 'Enter the overall cost of the project.',
        errorRequired: 'Enter the estimated total cost.',
        maxLength: 13,
      },
      lgffFundingAmountRequested: {
        name: 'app-project-lgff-funding-amount-requested',
        nameCtr: 'lgffFundingAmountRequested',
        labelText: 'LGFF funding amount requested',
        helpTextRaw: 'Enter the LGFF grant funding requested for approval.',
        errorRequired: 'Enter the LGFF funding amount requested.',
        maxLength: 13,
      },
      fundingFromOtherGrantPrograms: {
        name: 'app-project-funding-from-other-grant-programs',
        nameCtr: 'fundingFromOtherGrantPrograms',
        labelText: 'Funding from other grant programs',
        helpTextRaw: 'Enter the project costs being funded by other grants.',
        errorRequired: 'Enter an amount of 0 or greater for the funding from other grant programs.',
      },
      fundingFromMunicipalSources: {
        name: 'app-project-funding-from-municipal-sources',
        nameCtr: 'fundingFromMunicipalSources',
        labelText: 'Funding from municipal sources',
        helpTextRaw: 'This value is calculated based on funding amounts entered above.',
      },
      locationDescription: {
        name: 'app-project-location-description',
        nameCtr: 'prjLocDescription',
        labelText: 'Location description (optional)',
        maxLength: 1000,
        helpTextRaw: 'Add a description about your location',
      },
    },
  },
  cashFlow: {
    contact: {
      firstName: {
        name: 'cashflow-contact-first-name',
        nameCtr: 'formCtrFirstName',
        labelText: 'First name',
        errorRequired: 'Enter a first name.',
        maxLength: 63,
      },
      lastName: {
        name: 'cashflow-contact-last-name',
        nameCtr: 'formCtrLastName',
        labelText: 'Last name',
        errorRequired: 'Enter a last name.',
        maxLength: 63,
      },
      phone: {
        name: 'cashflow-contact-phone',
        nameCtr: 'formCtrPhone',
        labelText: 'Phone number',
        errorRequired: 'Enter a valid 10 digit phone number.',
        patternRule: 'GoaPhone',
        maxLength: 12,
      },
      email: {
        name: 'cashflow-contact-email',
        nameCtr: 'formCtrEmail',
        labelText: 'Email address',
        errorRequired: 'Enter a valid email address (e.g., myname@mycity.ca).',
        patternRule: 'email',
        maxLength: 63,
      },
    },
    applicationFunding: {
      reasonForUpdate: {
        name: 'reason-for-update',
        nameCtr: 'formCtrReasonForUpdate',
        labelText: 'Reason for update',
        errorRequired: 'Enter a reason for the update.',
        maxLength: 250,
      },
    },
    projectUpdates: {
      withdrawProject: {
        name: 'cashflow-project-withdraw-project',
        nameCtr: 'withdrawProject',
        labelText: 'Withdraw project',
      },
      quantityNew: {
        name: 'cashflow-project-quantity-new',
        nameCtr: 'quantityNew',
        labelText: 'Quantity: new',
        helpTextRaw: 'Indicate the quantity of new infrastructure in this project.',
        errorRequired: 'Enter the quantity for the new asset.',
      },
      quantityUpgrade: {
        name: 'cashflow-project-quantity-upgrade',
        nameCtr: 'quantityUpgrade',
        labelText: 'Quantity: upgrade',
        helpTextRaw: 'Indicate the quantity of infrastructure upgrades in this project.',
        errorRequired: 'Enter the quantity for asset to be upgraded.',
      },
      anticipatedStartDate: {
        name: 'cashflow-project-anticipated-start-date',
        nameCtr: 'anticipatedStartDate',
        labelText: 'Anticipated start date',
        errorRequired: 'Choose a start date.',
        patternRule: 'GoaDate',
        errorMinimumDate: 'Start date cannot be earlier than lgff system start date.',
        minimumDateRule: DateOnOrLaterThanSysStartDate,
        errorCompareDateLessThan: 'Start date cannot be after the end date.',
        dateLessThanField: 'anticipatedEndDate',
        helpTextRaw: 'Format: MM/DD/YYYY.',
      },
      anticipatedEndDate: {
        name: 'cashflow-project-anticipated-end-date',
        nameCtr: 'anticipatedEndDate',
        labelText: 'Anticipated end date',
        errorRequired: 'Choose an end date.',
        patternRule: 'GoaDate',
        errorMinimumDate: 'End date cannot be earlier than lgff system start date.',
        minimumDateRule: DateOnOrLaterThanSysStartDate,
        errorCompareDateGreaterThan: 'End date cannot be earlier than the start date.',
        dateGreaterThanField: 'anticipatedStartDate',
        helpTextRaw: 'Format: MM/DD/YYYY.',
      },
      estimatedTotalCost: {
        name: 'cashflow-project-estimated-total-cost',
        nameCtr: 'estimatedTotalCost',
        labelText: 'Estimated total cost',
        helpTextRaw: 'Enter the overall cost of the project.',
        errorRequired: 'Enter the estimated total cost.',
        maxLength: 13,
      },
      lgffFundingAmountRequested: {
        name: 'cashflow-project-lgff-funding-amount-requested',
        nameCtr: 'lgffFundingAmountRequested',
        labelText: 'LGFF funding amount requested',
        helpTextRaw: 'Enter the LGFF grant funding requested for approval.',
        errorRequired: 'Enter the LGFF funding amount requested.',
        maxLength: 13,
      },
      fundingFromOtherGrantPrograms: {
        name: 'cashflow-project-funding-from-other-grant-programs',
        nameCtr: 'fundingFromOtherGrantPrograms',
        labelText: 'Funding from other grant programs',
        helpTextRaw: 'Enter the project costs being funded by other grants.',
        errorRequired: 'Enter an amount of 0 or greater for the funding from other grant programs.',
      },
      fundingFromMunicipalSources: {
        name: 'cashflow-project-funding-from-municipal-sources',
        nameCtr: 'fundingFromMunicipalSources',
        labelText: 'Funding from municipal sources',
        helpTextRaw: 'This value is calculated based on funding amounts entered above.',
      },
      reasonForUpdate: {
        name: 'cashflow-project-reason-for-update',
        nameCtr: 'reasonForUpdate',
        labelText: 'Reason for update',
        maxLength: 400,
        errorRequired: 'Enter a reason for the update',
      },
    },
  },
  sepo: {
    contact: {
      firstName: {
        name: 'sepo-contact-first-name',
        nameCtr: 'formCtrFirstName',
        labelText: 'First name',
        errorRequired: 'Enter a first name.',
        maxLength: 63,
      },
      lastName: {
        name: 'sepo-contact-last-name',
        nameCtr: 'formCtrLastName',
        labelText: 'Last name',
        errorRequired: 'Enter a last name.',
        maxLength: 63,
      },
      phone: {
        name: 'sepo-contact-phone',
        nameCtr: 'formCtrPhone',
        labelText: 'Phone number',
        errorRequired: 'Enter a valid 10 digit phone number.',
        patternRule: 'GoaPhone',
        maxLength: 12,
      },
      email: {
        name: 'sepo-contact-email',
        nameCtr: 'formCtrEmail',
        labelText: 'Email address',
        errorRequired: 'Enter a valid email address, such as myname@gov.ab.ca.',
        patternRule: 'email',
        maxLength: 63,
      },
    },
    projectUpdates: {
      applicationId: {
        name: 'sepo-application-id',
        nameCtr: 'applicationId',
        labelText: 'Application ID',
      },
      applicationName: {
        name: 'sepo-application-name',
        nameCtr: 'applicationName',
        labelText: 'Application Name',
      },
      applicationStatus: {
        name: 'sepo-application-status',
        nameCtr: 'applicationStatus',
        labelText: 'Application Status',
      },
      projectId: {
        name: 'sepo-project-id',
        nameCtr: 'projectId',
        labelText: 'Project ID',
      },
      projectName: {
        name: 'sepo-project-name',
        nameCtr: 'projectName',
        labelText: 'Project Name',
      },
      projectStatus: {
        name: 'sepo-project-status',
        nameCtr: 'projectStatus',
        labelText: 'Project status',
        errorRequired: 'Select a project status.',
      },
      estimatedTotalCost: {
        name: 'sepo-estimated-total-cost',
        nameCtr: 'estimatedTotalCost',
        labelText: 'Total estimated LGFF funding',
      },
      fundingRemaining: {
        name: 'sepo-funding-remaining',
        nameCtr: 'fundingRemaining',
        labelText: 'LGFF funding remaining',
      },
      actualReportingYearCost: {
        name: 'sepo-actual-reporting-year-cost',
        nameCtr: 'actualReportingYearCost',
        labelText: 'Actual reporting year cost',
      },
      fundingFromOtherGrantPrograms: {
        name: 'sepo-funding-from-other-grant-programs',
        nameCtr: 'fundingFromOtherGrantPrograms',
        labelText: 'Portion funded by other grant programs',
      },
      fundingApplied: {
        name: 'sepo-funding-applied',
        nameCtr: 'fundingApplied',
        labelText: 'LGFF funding applied this reporting year',
      },
      fundingFromMunicipalSources: {
        name: 'sepo-funding-from-municipal-sources',
        nameCtr: 'fundingFromMunicipalSources',
        labelText: 'Portion funded from municipal sources ',
        helpTextRaw: 'This value is calculated based on funding amounts entered above.',
      },
      amountExpendedFromLGFFToDate: {
        name: 'sepo-amountExpendedFromLGFFToDate',
        nameCtr: 'amountExpendedFromLGFFToDate',
        labelText: 'amountExpendedFromLGFFToDate',
      },
    },
    immediateOutcomesProjectUpdates: {
      applicationId: {
        name: 'sepo-application-id',
        nameCtr: 'applicationId',
        labelText: 'Application ID',
      },
      applicationName: {
        name: 'sepo-application-name',
        nameCtr: 'applicationName',
        labelText: 'Application Name',
      },
      projectId: {
        name: 'sepo-project-id',
        nameCtr: 'projectId',
        labelText: 'Project ID',
      },
      projectName: {
        name: 'sepo-project-name',
        nameCtr: 'projectName',
        labelText: 'Project Name',
      },
      primaryCapitalAsset: {
        name: 'sepo-primary-capital-asset',
        nameCtr: 'primaryCapitalAsset',
        labelText: 'Primary Capital asset',
      },
      quantityNew: {
        name: 'sepo-project-quantity-new',
        nameCtr: 'quantityNew',
        labelText: 'Quantity: new',
        helpTextRaw: 'Indicate the quantity of new infrastructure in this project.',
        errorRequired: 'Enter the quantity for the new asset.',
      },
      quantityUpgrade: {
        name: 'sepo-project-quantity-upgrade',
        nameCtr: 'quantityUpgrade',
        labelText: 'Quantity: upgrade',
        helpTextRaw: 'Indicate the quantity of infrastructure upgrades in this project.',
        errorRequired: 'Enter the quantity for asset to be upgraded.',
      },
      currentAssetRanking: {
        name: 'sepo-project-current-asset-ranking',
        nameCtr: 'currentAssetRanking',
        labelText: 'Current condition ranking of upgraded asset',
        placeHolderRaw: 'Please select a ranking',
        errorRequired: 'Select a ranking.',
      },
      projectPrimaryOutcome: {
        name: 'sepo-project-primary-outcome',
        nameCtr: 'projectPrimaryOutcome',
        labelText: 'Project primary outcome',
        placeHolderRaw: 'Please select an outcome',
        errorRequired: 'Select an outcome.',
      },
    },
  },
};
