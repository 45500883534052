<div class="mb-56px" style="margin-top: 0.75rem">
  <goa-callout
    #calloutImp
    type="important"
    heading="{{ 'errorCalloutHeading' | res: 'COMMON' }}"
    *ngIf="projectStatusValidationCallout"
  >
    <p>{{ this.projectExpenditureStatusCalloutHeading }}</p>
    <a
      *ngIf="this.projectEditForm?.get('actualReportingYearCost')?.value"
      (click)="jumpToField(UiDefActualReportingYearCost.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefActualReportingYearCost.labelText }}<br
    /></a>
    <a
      *ngIf="this.projectEditForm?.get('fundingFromOtherGrantPrograms')?.value"
      (click)="jumpToField(UiDefFundingFromOtherGrantPrograms.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefFundingFromOtherGrantPrograms.labelText }}<br
    /></a>
    <a
      *ngIf="this.projectEditForm?.get('fundingApplied')?.value"
      (click)="jumpToField(UiDefLgffFundingApplied.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefLgffFundingApplied.labelText }}<br
    /></a>
  </goa-callout>
  <goa-callout
    #callout
    type="emergency"
    heading="{{ 'errorCalloutHeading' | res: 'COMMON' }}"
    *ngIf="showErrorFieldsCallout"
  >
    <ng-container *ngFor="let uiDef of pageUiDefs">
      <a
        *ngIf="uiDef.errorMsg && uiDef.nameCtr != 'actualReportingYearCost'"
        (click)="jumpToField(uiDef.idWrap ?? '')"
        class="validation-message-link"
      >
        {{ uiDef.labelText }}
        <br />
      </a>
    </ng-container>

    <!-- show extra custom error, and prevent duplicate field-label showing -->
    <a
      *ngIf="
        shouldShowlgffFundingReportingYearCostRequiredMessage ||
        this.projectStatusControlValueValidationMessage('actualReportingYearCost') ||
        shouldShowlgffZeroReportingYearCostNotValidMessage
      "
      (click)="jumpToField(UiDefActualReportingYearCost.id + '-wrap')"
      class="validation-message-link"
      >{{ reportingCostLabel }}<br
    /></a>

    <a
      *ngIf="
        shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage ||
        shouldShowlgffFundingFromOtherGrantProgramsRequiredMessage ||
        this.projectStatusControlValueValidationMessage('fundingFromOtherGrantPrograms')
      "
      (click)="jumpToField(UiDefFundingFromOtherGrantPrograms.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefFundingFromOtherGrantPrograms.labelText }}<br
    /></a>
    <a
      *ngIf="
        shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage ||
        shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage ||
        shouldShowlgffFundingAmountAppliedRequiredMessage ||
        this.projectStatusControlValueValidationMessage('fundingApplied') ||
        shouldShowlgffZeroFundingAmountAppliedNotValidMessage
      "
      (click)="jumpToField(UiDefLgffFundingApplied.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefLgffFundingApplied.labelText }}<br
    /></a>
    <a
      *ngIf="shouldShowPortionMunicipalSourceNegativeValidationMessage"
      (click)="jumpToField(UiDefFundingFromMunicipalSources.id + '-wrap')"
      class="validation-message-link"
      >{{ UiDefFundingFromMunicipalSources.labelText }}<br />
    </a>
  </goa-callout>
</div>
<form [formGroup]="projectEditForm">
  <div class="edit-project-container">
    <goa-form-item [label]="'Application ID'" id="application-id">
      <p>{{ this.sepoProjectToUpdate?.applicationIdTxt ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="xl"></goa-spacer>
    <goa-form-item [label]="'Application name'" id="application-name" class="application-name">
      <p>{{ this.sepoProjectToUpdate?.applicationName ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="xl"></goa-spacer>
    <goa-form-item [label]="'Project ID'" id="project-id">
      <p>{{ this.sepoProjectToUpdate?.projectIdTxt ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="xl"></goa-spacer>
    <goa-form-item [label]="'Project name'" id="project-name" class="project-name">
      <p>{{ this.sepoProjectToUpdate?.projectName ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="xl"></goa-spacer>

    <ng-container *ngIf="!canEditProjectStatus">
      <goa-form-item [label]="'Project status'" id="project-status" class="project-status">
        <p>{{ this.sepoProjectToUpdate?.projectStatusTitle ?? '' }}</p>
      </goa-form-item>
    </ng-container>

    <ng-container *ngIf="canEditProjectStatus">
      <div *ngIf="UiDefProjectStatus; let UiDef" class="gene-app-info-wd">
        <goa-form-item
          class="app-section-item"
          [attr.id]="UiDef.id + '-wrap'"
          [label]="UiDef.labelText"
          [helptext]="UiDef.helptext"
        >
          <goa-dropdown
            width="22rem"
            maxheight="250px"
            relative="true"
            name="{{ UiDef.name }}"
            [attr.id]="UiDef.id"
            placeholder="Please select a status"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            (focusin)="onFocusIn(UiDef, true)"
            (focusout)="onFocusOut(UiDef, true)"
            (_change)="onChangeProjectStatus()"
          >
            <goa-dropdown-item
              *ngFor="let projectStatus of projectStatus"
              id="{{ UiDefProjectStatus.id }} + {{ projectStatus.id + 1 }}"
              [value]="projectStatus.id"
              [label]="projectStatus.title"
            >
            </goa-dropdown-item>
          </goa-dropdown>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>
    </ng-container>

    <goa-spacer vSpacing="xl"></goa-spacer>
    <goa-form-item [label]="estimatedFundingLabel" id="total-estimated-funding-id">
      <p>$ {{ this.sepoProjectToUpdate?.estimatedTotalLGFFFunding?.toLocaleString() ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="xl"></goa-spacer>
    <goa-form-item
      [label]="'LGFF funding available for this reporting year'"
      id="funding-remaining-id"
      class="funding-remaining"
    >
      <p>$ {{ this.sepoProjectToUpdate?.totalRemaining?.toLocaleString() ?? '' }}</p>
    </goa-form-item>
    <goa-spacer vSpacing="xl"></goa-spacer>
    <div class="fi-container-pb" *ngIf="UiDefActualReportingYearCost; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="reportingCostLabel">
          <goa-input
            type="text"
            commaFormatNumber
            [allowZero]="allowZero"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="
              UiDef.errorMsg ||
              shouldShowlgffFundingReportingYearCostRequiredMessage ||
              shouldShowlgffFundingReportingYearCostNotRequiredMessage ||
              shouldShowlgffZeroReportingYearCostNotValidMessage
            "
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>
          <div
            *ngIf="
              projectStatusControlValueValidationMessage(UiDef.nameCtr) &&
              shouldShowlgffFundingReportingYearCostNotRequiredMessage
            "
            class="long-error-msg"
          >
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ this.projectStatusAmountError }}
            </div>
          </div>
          <div *ngIf="shouldShowlgffFundingReportingYearCostRequiredMessage" class="long-error-msg">
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ this.lgffFundingReportingYearCostRequiredMessage }}
            </div>
          </div>
          <div *ngIf="shouldShowlgffZeroReportingYearCostNotValidMessage" class="error-msg amount-error-msg">
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ errorMsgOnZeroAmountWithNoPreviousExpenditure }}
            </div>
          </div>
        </goa-form-item>

        <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
      </div>
    </div>
    <goa-spacer vSpacing="l"></goa-spacer>

    <div class="fi-container-pb" *ngIf="UiDefFundingFromOtherGrantPrograms; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [allowZero]="allowZero"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="
              UiDef.errorMsg ||
              shouldShowlgffFundingFromOtherGrantProgramsRequiredMessage ||
              shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage ||
              shouldShowlgffFundingFromOtherGrantProgramsNotRequiredMessage
            "
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>
          <div
            *ngIf="
              projectStatusControlValueValidationMessage(UiDef.nameCtr) &&
              shouldShowlgffFundingFromOtherGrantProgramsNotRequiredMessage
            "
            class="long-error-msg"
          >
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ this.projectStatusAmountError }}
            </div>
          </div>
          <div *ngIf="shouldShowlgffFundingFromOtherGrantProgramsRequiredMessage" class="long-error-msg">
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ lgffFundingFromOtherGrantProgramsRequiredMessage }}
            </div>
          </div>
          <div
            *ngIf="
              (UiDef.errorMsg || shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage) &&
              shouldShowAmountErrorOnProjectStatus
            "
          >
            <div
              *ngIf="shouldShowfundingFromOtherGrantProgramsVsReportingYearCostValidationMessage"
              class="long-error-msg"
            >
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ fundingFromOtherGrantProgramsVsReportingYearCostValidationMessage }}
              </div>
            </div>
            <!-- <div *ngIf="shouldShowcombinedFundingVsReportingYearCostValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ combinedFundingVsReportingYearCostValidationMessage }}
              </div>
            </div> -->
          </div>
        </goa-form-item>
      </div>
    </div>
    <goa-spacer vSpacing="l"></goa-spacer>
    <div class="fi-container-pb" *ngIf="UiDefLgffFundingApplied; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [allowZero]="allowZero"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="
              UiDefActualReportingYearCost.errorMsg ||
              shouldShowlgffFundingAmountAppliedRequiredMessage ||
              shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage ||
              shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage ||
              shouldShowlgffFundingAmountAppliedNotRequiredMessage ||
              shouldShowlgffZeroFundingAmountAppliedNotValidMessage
            "
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>
          <div
            *ngIf="
              projectStatusControlValueValidationMessage(UiDef.nameCtr) &&
              shouldShowlgffFundingAmountAppliedNotRequiredMessage
            "
            class="long-error-msg"
          >
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ this.projectStatusAmountError }}
            </div>
          </div>
          <div *ngIf="shouldShowlgffFundingAmountAppliedRequiredMessage" class="long-error-msg">
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ lgffFundingAmountAppliedRequiredMessage }}
            </div>
          </div>
          <div
            *ngIf="
              (UiDef.errorMsg ||
                shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage ||
                shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage ||
                shouldShowlgffZeroFundingAmountAppliedNotValidMessage) &&
              shouldShowAmountErrorOnProjectStatus
            "
          >
            <div *ngIf="shouldShowlgffFundingAmountAppliedVsReportingYearCostValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ lgffFundingAmountAppliedVsReportingYearCostValidationMessage }}
              </div>
            </div>
            <div *ngIf="shouldShowlgffZeroFundingAmountAppliedNotValidMessage" class="error-msg amount-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ errorMsgOnZeroAmountWithNoPreviousExpenditure }}
              </div>
            </div>
            <div *ngIf="shouldShowlgffFundingAmountAppliedVsFundingRemainingValidationMessage" class="long-error-msg">
              <div [id]="UiDef.nameCtr + '-error'">
                <goa-icon type="warning" size="small" theme="filled" />
                {{ lgffFundingAmountAppliedVsFundingRemainingValidationMessage }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>

      <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>

    <goa-spacer vSpacing="l"></goa-spacer>

    <div class="fi-container-pb" *ngIf="UiDefFundingFromMunicipalSources; let UiDef">
      <div class="currency-input-width">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            commaFormatNumber
            [attr.disabled]="true"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            [error]="shouldShowPortionMunicipalSourceNegativeValidationMessage"
          >
            <div slot="leadingContent">
              <span style="font-size: 14px; padding-top: 3px; align-content: center; height: 25.343px">$</span>
            </div>
          </goa-input>
          <div *ngIf="shouldShowPortionMunicipalSourceNegativeValidationMessage" class="long-error-msg">
            <div [id]="UiDef.nameCtr + '-error'">
              <goa-icon type="warning" size="small" theme="filled" />
              {{ 'portionMunicipalSourceNegativeValidationMessageLine1' | res: pageId }}
              <br />
              {{ 'portionMunicipalSourceNegativeValidationMessageLine2' | res: pageId }}
            </div>
          </div>
        </goa-form-item>
      </div>
      <div class="help-msg-prj-fund" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
    </div>
    <goa-spacer vSpacing="l"></goa-spacer>
  </div>
</form>
