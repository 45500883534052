import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AutosaveStatus, FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import {
  ResetSEPOState,
  SetEditSepoButtonAction,
  SetEditSepoCurrentStep,
  SetSEPO,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { EditSepoSequence } from './enum/edit-sepo-sequence.enum';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { SepoExtService } from '@app-com/api/services';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';
import { ResourcePipe } from '@app-com/pipes';
import { Router } from '@angular/router';
import { SharedEditSepoService } from './edit-sepo-shared.service';
import { SepoExtDto } from '@app-com/api/models';
import { CommUtilsService } from '@app-com/services/comm-utils.service';

@Component({
  selector: 'app-edit-sepo',
  templateUrl: './edit-sepo.component.html',
  styleUrl: './edit-sepo.component.scss',
})
export class EditSepoComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  EditSepoSequence = EditSepoSequence;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  @Select(EditSepoState.getEditSepoStepperStatus) stepperState$: Observable<{
    [EditSepoSequence.ContactInfo]: FormStatusCodes.NotStarted;
    [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.NotStarted;
    [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted;
    [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted;
    [EditSepoSequence.Certification]: FormStatusCodes.NotStarted;
  }>;

  @Select(EditSepoState.getAutosaveStatus) autosaveStatus$: Observable<AutosaveStatus>;
  @Select(EditSepoState.getSepoDetails) sepo$: Observable<SepoExtDto>;
  sepoDetail: SepoExtDto = {} as SepoExtDto;

  sub = new Subscription();
  currentStep: EditSepoSequence | undefined;
  isDeleteDraftDialogOpen = false;
  currentSepoId: number | undefined;
  organizationId: number | undefined;
  formStepContactInfo = false;
  isDeleteEnabled = false;
  constructor(
    private store: Store,
    private sepoService: SepoExtService,
    private snackbarService: SnackBarService,
    private res: ResourcePipe,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private sharedFormService: SharedEditSepoService,
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.currentStep$?.subscribe((step) => {
        this.currentStep = step;
        this.formStepContactInfo = !!step && step == EditSepoSequence.ContactInfo;
      }),
    );
    this.sub.add(
      this.sepo$?.subscribe((sepo) => {
        this.sepoDetail = sepo;
      }),
    );
    this.getAllSepoData();

    this.sub.add(
      this.sharedFormService.formFilled$.subscribe((isFilled) => {
        if (this.currentStep !== EditSepoSequence.ContactInfo) {
          this.isDeleteEnabled = true;
        } else {
          this.isDeleteEnabled = isFilled;
        }
      }),
    );

    this.cdr.detectChanges();
  }

  get AutosaveStatus(): typeof AutosaveStatus {
    return AutosaveStatus;
  }

  getAllSepoData() {
    this.currentSepoId = this.store.selectSnapshot(CurrentContextState.getCurrentSEPOId);
    this.organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);

    if (this.currentSepoId) {
      this.sub.add(
        this.sepoService.findOne({ id: this.currentSepoId, organizationId: this.organizationId }).subscribe({
          next: (value) => {
            this.store.dispatch(new SetSEPO(value));
          },
        }),
      );
    }

    this.sub.add(
      this.sharedFormService.formFilled$.subscribe((isFilled) => {
        this.isDeleteEnabled = isFilled;
      }),
    );

    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ContactInfo));
  }

  onPreviousClicked() {
    this.store.dispatch(new SetEditSepoButtonAction(['previous']));
    CommUtilsService.scrollToTop();
  }

  onNextClicked() {
    const step = this.store.selectSnapshot(EditSepoState.getEditSepoCurrentStep);
    if (step !== 3) {
      CommUtilsService.scrollToTop();
    }
    this.store.dispatch(new SetEditSepoButtonAction(['next']));
  }

  onSubmitClicked() {
    this.store.dispatch(new SetEditSepoButtonAction(['submit']));
  }

  onSaveAndCloseClicked() {
    this.store.dispatch(new SetEditSepoButtonAction(['save']));
  }

  async onDeleteDraftClicked(data: { deleteDraft: boolean }) {
    this.isDeleteDraftDialogOpen = false;

    if (!data.deleteDraft) return;
    this.store.dispatch(new SetEditSepoButtonAction(['delete']));

    const onSuccess = () => {
      this.snackbarService.showSuccessMessage(this.res.transform('deleteDraftDataSuccessSnack', this.pageId));
      this.store.dispatch(new ResetSEPOState());
      this.getAllSepoData();
      this.router.navigate(['edit-sepo/contact-info']);
    };

    if (this.currentSepoId && this.organizationId) {
      this.sepoService
        .deleteDraftData({ id: this.currentSepoId, organizationId: this.organizationId })
        .subscribe({ next: onSuccess });
    } else {
      onSuccess();
    }
  }
}
