import { ApplicationCertifiedByRole, ApplicationDraftRecordDto } from '@app-com/api/models';
import { ProjectRecordVm } from '@app-pot/features/grant-application/models';
import { FormSequence, FormStatusCodes } from '@app-pot/features/grant-application/models/enums';

export class FetchApplicationDraft {
  static readonly type = '[ApplicationDraft] Fetch Draft';
  constructor(public applicationDraftId: number) {}
}

export class SetApplicationDraftId {
  static readonly type = '[ApplicationDraft] Set Id';
  constructor(public applicationDraftId: number) {}
}

export class DeleteApplicationDraft {
  static readonly type = '[ApplicationDraft] Cancel Delete';
  constructor(public applicationDraftId: number) {}
}

export class SetGeneralInfo {
  static readonly type = '[ApplicationDraft] Set General Info Application Draft Partial';
  constructor(public generalInfo: Partial<ApplicationDraftRecordDto>) {}
}

export class SetFunctionalCategories {
  static readonly type = '[ApplicationDraft] Set Functional Categories Application Draft Partial';
  constructor(public functionalCategories: Partial<ApplicationDraftRecordDto>) {}
}

export class SetProjects {
  static readonly type = '[ApplicationDraft] Set Projects Application Draft Partial';
  constructor(public projectRecords: ProjectRecordVm[]) {
    console.log('[SetProjects Action]Project Records', projectRecords);
  }
}

export class DeleteProjects {
  static readonly type = '[ApplicationDraft] Delete Projects Application Draft Partial';
}

export class SaveApplicationDraft {
  static readonly type = '[ApplicationDraft] Save Draft';
}

export class AutoSaveApplicationDraft {
  static readonly type = '[ApplicationDraft] Auto Save Draft';
}

export class AutosaveGeneralInfo {
  static readonly type = '[ApplicationDraft] Autosave General Info';
  constructor(public generalInfo: Partial<ApplicationDraftRecordDto>) {}
}

export class AutosaveFunctionalCategories {
  static readonly type = '[ApplicationDraft] Autosave Functional Categories';
  constructor(
    public payload: {
      functionalCategoryPage: Partial<ApplicationDraftRecordDto>;
      functionalCategory?: string;
      operation?: 'add' | 'update' | 'delete';
    },
  ) {}
}

export class AutosaveProjects {
  static readonly type = '[ApplicationDraft] Autosave Projects';
  constructor(
    public payload: { projects: ProjectRecordVm[]; projectName?: string; operation?: 'add' | 'update' | 'delete' },
  ) {}
}

export class ResetAutosaveState {
  static readonly type = '[ApplicationDraft] Reset Autosave State';
}

export class SetFormStepperStatus {
  static readonly type = '[ApplicationDraft] Set Form Stepper Staus';
  constructor(
    public formStepperStatus: Partial<{
      [Item in FormSequence]: FormStatusCodes;
    }>,
  ) {}
}

export class SetFormStep {
  static readonly type = '[ApplicationDraft] Set Form Step';
  constructor(public formStep: FormSequence) {}
}

export class SubmitApplication {
  static readonly type = '[ApplicationDraft] Submit an application draft';
  constructor(public certifiedByRole: ApplicationCertifiedByRole) {}
}

export class SetDraftApplicationCount {
  static readonly type = '[ApplicationDraft] Draft Application count';
  constructor(public draftApplicationCount: number) {}
}
