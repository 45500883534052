import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  FetchSEPODetails,
  SetEditSepoButtonAction,
  SetEditSepoCurrentStep,
  SetEditSepoStepperStatus,
  SetSEPOAssetManagementPlan,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourcePipe } from '@app-com/pipes';
import { LookupValue, LookupValueState } from '@app-com/state/lookup-value.state';
import { LoadSepoAssetManagementPlanBarriers } from '@app-pot/store/actions/lookup-value.action';
import { SepoExtDto, SepoExtUpdateDto } from '@app-com/api/models';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { formatNumberWithCommas } from '@app-com/directives';

@Component({
  selector: 'app-edit-sepo-asset-fund',
  templateUrl: './edit-sepo-asset-fund.component.html',
  styleUrl: './edit-sepo-asset-fund.component.scss',
})
export class EditSepoAssetFundComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  @Select(LookupValueState.getSepoAssetManagementPlanBarriers) assetManagementPlanBarriers$: Observable<LookupValue[]>;
  @Select(EditSepoState.getSepoDetails) getSepoDetails$: Observable<SepoExtDto>;
  assetManagementPlanBarriers: { id: number; title: string }[];
  assetManagementPlanBarrierListClose: boolean;
  assetFundHeading: string;

  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this._prepareForm();
  }
  assetManagementForm: FormGroup;
  errors: { [key: string]: string } = {};
  errorsA: { [key: string]: string } = {};
  creditAmountForm: FormGroup;
  today = new Date();
  pageId = 'SEPO';
  sub = new Subscription();
  saveButtonClickValidation = false;
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  @Select(EditSepoState.getSepoDetails) sepo$: Observable<SepoExtDto>;
  currentStep: EditSepoSequence | undefined;
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  private _prepareForm() {
    this.assetManagementForm = this.formBuilder.group({
      hasAssetManagementPlan: [''],
      hasAssetManagementPlanNo: [''],
      assetManagementPlanLastUpdatedAt: [''],
      assetManagementPlanBarriers: [''],
      assetManagementPlanOther: ['', [Validators.maxLength(100)]],
    });

    this.creditAmountForm = new FormGroup({
      amountCarryForward: new FormControl(''),
      amountFundingAllocation: new FormControl(''),
      totalFundingAvailable: new FormControl(''),
      amountCredit: new FormControl('', [Validators.required]),
    });
  }

  getSepoAssetFundsDataFromStore() {
    const storeSepo = this.store.selectSnapshot(EditSepoState.getSepoDetails);
    if (storeSepo) {
      if (storeSepo.hasAssetManagementPlan != undefined) {
        this.assetManagementForm?.patchValue({
          hasAssetManagementPlan: storeSepo.hasAssetManagementPlan,
          hasAssetManagementPlanNo:
            storeSepo.hasAssetManagementPlan != undefined && storeSepo.hasAssetManagementPlan == false ? true : false,
        });
      }
      if (storeSepo.assetManagementPlanBarriers && storeSepo.assetManagementPlanBarriers?.length > 0) {
        this.assetManagementForm?.patchValue({
          assetManagementPlanBarriers: storeSepo.assetManagementPlanBarriers?.map(({ id }) => id),
        });
      }
      if (storeSepo.assetManagementPlanOther) {
        this.assetManagementForm?.patchValue({
          assetManagementPlanOther: storeSepo.assetManagementPlanOther,
        });
      }
      if (storeSepo.assetManagementPlanLastUpdatedAt) {
        this.assetManagementForm?.patchValue({
          assetManagementPlanLastUpdatedAt: storeSepo.assetManagementPlanLastUpdatedAt,
        });
      }
      if (storeSepo.hasAssetManagementPlan === true) {
        this.setRadioCheckedState('hasAssetManagementPlanNo', false);
      } else if (storeSepo.hasAssetManagementPlan === false) {
        this.setRadioCheckedState('hasAssetManagementPlanNo', true);
      }
      this.setRadioCheckedState('hasAssetManagementPlan', storeSepo.hasAssetManagementPlan ?? false);

      this.creditAmountForm?.patchValue({
        amountCarryForward: formatNumberWithCommas(storeSepo.amountCarryForward),
        amountFundingAllocation: formatNumberWithCommas(storeSepo.amountFundingAllocation),
        totalFundingAvailable: formatNumberWithCommas(
          (storeSepo.amountCarryForward ?? 0) +
            (storeSepo.amountFundingAllocation ?? 0) +
            (storeSepo.amountCredit ?? 0),
        ),
      });

      if (storeSepo.amountCredit !== null && storeSepo.amountCredit !== undefined) {
        this.creditAmountForm?.patchValue({
          amountCredit: formatNumberWithCommas(storeSepo.amountCredit),
        });
      }
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.AssetManagementFunds));
    this.store.dispatch(
      new SetEditSepoStepperStatus({
        [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
        [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.NotStarted,
      }),
    );
    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.AssetManagementFunds) {
          console.log(
            'file: edit-sepo-asset-fund.component.ts:45 ~ EditSepoAssetFundComponent ~ this.currentStep$.subscribe ~ this.currentStep:',
            this.currentStep,
          );
        }
      }),
    );
    this.store.dispatch(new LoadSepoAssetManagementPlanBarriers());
    this.sub.add(
      this.assetManagementPlanBarriers$.subscribe((assetManagementPlanBarriers) => {
        this.assetManagementPlanBarriers = assetManagementPlanBarriers.map((list) => {
          return {
            id: list.id,
            title: list.title,
          };
        });
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          this.saveButtonClickValidation = true;
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.StatementExpenditures));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.store.dispatch(new SetSEPOAssetManagementPlan(this.getSepoAssetManagementFundsToSave()));
            this.store.dispatch(new FetchSEPODetails());
            this.router.navigate(['edit-sepo/statement-of-expenditures']);
          }
        } else {
          this.saveButtonClickValidation = false;
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ContactInfo));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            console.log('return to previous : edit-sepo/contact-info');
            this.router.navigate(['edit-sepo/contact-info']);
          }
        }
        if (buttonAction && buttonAction.indexOf('save') >= 0) {
          this.onSaveAndClose();
          this.router.navigate(['/view-sepo']);
        }
      }),
    );

    this.sub.add(
      this.sepo$?.subscribe((storeSepo) => {
        this.assetManagementForm?.patchValue({
          hasAssetManagementPlan: storeSepo.hasAssetManagementPlan,
          hasAssetManagementPlanNo:
            storeSepo.hasAssetManagementPlan != undefined && storeSepo.hasAssetManagementPlan == false ? true : false,
        });
        if (storeSepo.assetManagementPlanBarriers && storeSepo.assetManagementPlanBarriers?.length > 0) {
          this.assetManagementForm?.patchValue({
            assetManagementPlanBarriers: storeSepo.assetManagementPlanBarriers?.map(({ id }) => id),
          });
        }
        if (storeSepo.assetManagementPlanOther) {
          this.assetManagementForm?.patchValue({
            assetManagementPlanOther: storeSepo.assetManagementPlanOther,
          });
        }
        if (storeSepo.assetManagementPlanLastUpdatedAt) {
          this.assetManagementForm?.patchValue({
            assetManagementPlanLastUpdatedAt: storeSepo.assetManagementPlanLastUpdatedAt,
          });
        }
        if (storeSepo.hasAssetManagementPlan === true) {
          this.setRadioCheckedState('hasAssetManagementPlanNo', false);
        } else if (storeSepo.hasAssetManagementPlan === false) {
          this.setRadioCheckedState('hasAssetManagementPlanNo', true);
        }
        this.setRadioCheckedState('hasAssetManagementPlan', storeSepo.hasAssetManagementPlan ?? false);

        this.creditAmountForm?.patchValue({
          amountCarryForward: formatNumberWithCommas(storeSepo.amountCarryForward),
          amountFundingAllocation: formatNumberWithCommas(storeSepo.amountFundingAllocation),
          totalFundingAvailable: formatNumberWithCommas(
            (storeSepo.amountCarryForward ?? 0) +
              (storeSepo.amountFundingAllocation ?? 0) +
              (storeSepo.amountCredit ?? 0),
          ),
        });
        if (storeSepo.amountCredit) {
          this.creditAmountForm?.patchValue({
            amountCredit: formatNumberWithCommas(storeSepo.amountCredit),
          });
        }
        if (storeSepo?.programYear) {
          this.assetFundHeading = this.res.transform('assetFundHeading2', this.pageId, [storeSepo?.programYear]);
        } else {
          this.assetFundHeading = this.res.transform('assetFundHeading2', this.pageId, ['']);
        }
      }),
    );

    this.getSepoAssetFundsDataFromStore();
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getSepoAssetManagementFundsToSave(): Partial<SepoExtUpdateDto> {
    const assetManagementFundsData: Partial<SepoExtUpdateDto> = {};

    assetManagementFundsData.hasAssetManagementPlan = this.assetManagementForm.controls['hasAssetManagementPlan'].value
      ? true
      : false;

    if (
      this.assetManagementForm.controls['assetManagementPlanLastUpdatedAt'].value &&
      this.assetManagementForm.controls['hasAssetManagementPlan'].value == true
    ) {
      assetManagementFundsData.assetManagementPlanLastUpdatedAt =
        this.assetManagementForm.controls['assetManagementPlanLastUpdatedAt'].value;
      assetManagementFundsData.assetManagementPlanBarriers = [];
      assetManagementFundsData.assetManagementPlanOther = '';
    } else {
      assetManagementFundsData.assetManagementPlanLastUpdatedAt = '';
    }

    if (
      this.assetManagementForm.controls['hasAssetManagementPlanNo'].value == true &&
      this.assetManagementForm.controls['assetManagementPlanBarriers'].value &&
      this.assetManagementForm.controls['hasAssetManagementPlan'].value == false
    ) {
      assetManagementFundsData.assetManagementPlanBarriers = this.assetManagementForm.controls[
        'assetManagementPlanBarriers'
      ].value?.map((_: number) => {
        return {
          id: _,
          title: '',
        };
      });
      if (
        this.assetManagementForm
          .get('assetManagementPlanBarriers')
          ?.value?.indexOf(this.assetManagementPlanBarriers?.find((x) => x.title == 'Other (please describe)')?.id) > -1
      ) {
        if (this.assetManagementForm.controls['assetManagementPlanOther'].value) {
          assetManagementFundsData.assetManagementPlanOther =
            this.assetManagementForm.controls['assetManagementPlanOther'].value;
        } else {
          assetManagementFundsData.assetManagementPlanOther = '';
        }
      }
    } else {
      assetManagementFundsData.assetManagementPlanBarriers = [];
      assetManagementFundsData.assetManagementPlanOther = '';
    }

    if (
      this.creditAmountForm.controls['amountCredit'].value !== null &&
      this.creditAmountForm.controls['amountCredit'].value !== undefined
    ) {
      assetManagementFundsData.amountCredit = CommUtilsService.parseInputInt(
        this.creditAmountForm.controls['amountCredit'].value,
      );
    } else {
      assetManagementFundsData.amountCredit = null;
    }

    return assetManagementFundsData;
  }

  validateOnPrevious(): boolean {
    return true;
  }
  onSaveAndClose(): void {
    this.assetManagementPlanBarrierListClose = true;
    this.store.dispatch(new SetEditSepoButtonAction(['empty']));
    this.store.dispatch(new SetSEPOAssetManagementPlan(this.getSepoAssetManagementFundsToSave()));
  }

  validateOnNext(): boolean {
    this.assetManagementPlanBarrierListClose = true;
    this.checkAssetManagementErrors();
    this.checkCreditAmountErrors();
    this.checkAssetManagementBarrierErrorCallout;
    if (Object.keys(this.errors).length == 0 && Object.keys(this.errorsA).length == 0) {
      this.store.dispatch(new SetSEPOAssetManagementPlan(this.getSepoAssetManagementFundsToSave()));
      return true;
    } else {
      setTimeout(() => {
        this.jumpToField('gen-info-heading-1');
      }, 200);
      return false;
    }
  }
  get showErrorFieldsCallout(): boolean {
    const baseHasError =
      (Object.keys(this.errors).length > 0 ? true : false) || (Object.keys(this.errorsA).length > 0 ? true : false);
    return baseHasError && this.saveButtonClickValidation;
  }
  checkAssetManagementErrors() {
    Object.keys(this.assetManagementForm.controls).forEach((control) => {
      if (control != 'assetManagementPlanBarriers') {
        if (this.getErrorMessage(control)) {
          this.errors[control] = this.getErrorMessage(control);
        } else {
          delete this.errors[control];
        }
      }
    });
  }
  checkCreditAmountErrors() {
    Object.keys(this.creditAmountForm.controls).forEach((control) => {
      if (this.getErrorMessage(control)) {
        this.errorsA[control] = this.getErrorMessage(control);
      } else {
        delete this.errorsA[control];
      }
    });
  }
  get checkAssetManagementBarrierErrorCallout() {
    const control = 'assetManagementPlanBarriers';
    if (this.getErrorMessage(control) && this.assetManagementPlanBarrierListClose === true) {
      this.errors[control] = this.getErrorMessage(control);
    } else {
      delete this.errors[control];
    }
    return this.errors[control] != '' && this.errors[control]?.length > 0 ? true : false;
  }

  get checkAssetManagementErrorCallout() {
    let baseHasError = false;

    this.checkAssetManagementErrors();
    baseHasError = Object.keys(this.errors).length > 0 ? true : false;

    return baseHasError;
  }

  getErrorMessage(name: string): string {
    switch (name) {
      case 'hasAssetManagementPlan':
      case 'hasAssetManagementPlanNo':
        if (
          (this.assetManagementForm.get('hasAssetManagementPlan')?.value ?? false) == false &&
          (this.assetManagementForm.get('hasAssetManagementPlanNo')?.value ?? false) == false
        ) {
          return this.res.transform('assetFundPlanYesNoError', this.pageId);
        }
        break;
      case 'amountCredit':
        if (this.creditAmountForm.get('amountCredit')?.hasError('required')) {
          return this.res.transform('assetFundCreditAmountError', this.pageId);
        }
        break;
      case 'assetManagementPlanLastUpdatedAt':
        if (
          (this.assetManagementForm.get('hasAssetManagementPlan')?.value ?? false) == true &&
          (this.assetManagementForm.get('assetManagementPlanLastUpdatedAt')?.value ?? '') == ''
        ) {
          return this.res.transform('assetFundLastUpdatedAtError', this.pageId);
        }
        break;
      case 'assetManagementPlanBarriers':
        if (
          (this.assetManagementForm.get('hasAssetManagementPlanNo')?.value ?? false) == true &&
          this.assetManagementPlanBarrierListClose === true &&
          (this.assetManagementForm.get('assetManagementPlanBarriers')?.value?.length == 0 ||
            this.assetManagementForm.get('assetManagementPlanBarriers')?.value?.length > 3 ||
            this.assetManagementForm.get('assetManagementPlanBarriers')?.value === null ||
            this.assetManagementForm.get('assetManagementPlanBarriers')?.value === undefined)
        ) {
          return this.res.transform('assetFundSelectBarrierError', this.pageId);
        }
        break;
      case 'assetManagementPlanOther':
        if (
          this.assetManagementForm
            .get('assetManagementPlanBarriers')
            ?.value?.indexOf(this.assetManagementPlanBarriers?.find((x) => x.title == 'Other (please describe)')?.id) >
            -1 &&
          (this.assetManagementForm.get('assetManagementPlanOther')?.value == null ||
            ((this.assetManagementForm.get('assetManagementPlanOther')?.value ?? '') == '' &&
              (this.assetManagementForm.get('assetManagementPlanOther')?.value ?? '').trim()?.length == 0))
        ) {
          return this.res.transform('assetFundBarrierOtherError', this.pageId);
        } else if (
          this.assetManagementForm
            .get('assetManagementPlanBarriers')
            ?.value?.indexOf(this.assetManagementPlanBarriers?.find((x) => x.title == 'Other (please describe)')?.id) >
            -1 &&
          (this.assetManagementForm.get('assetManagementPlanOther')?.value ?? '').trim()?.length > 0 &&
          this.assetManagementForm?.controls['assetManagementPlanOther'].hasError('maxlength')
        ) {
          return this.res.transform('assetFundBarrierOtherMaxLengthError', this.pageId);
        }
        break;
      default:
        return '';
    }
    return '';
  }
  onFocusOut(controlName: string) {
    this.autoSave(controlName);
    if (this.getErrorMessage(controlName)) {
      this.errors[controlName] = this.getErrorMessage(controlName);
    } else {
      delete this.errors[controlName];
    }
  }
  onFocusIn(controlName: string) {
    delete this.errors[controlName];
  }
  onFocusOutA(controlName: string) {
    this.autoSave(controlName);
    this.creditAmountForm?.patchValue({
      totalFundingAvailable: formatNumberWithCommas(
        CommUtilsService.parseInputInt(this.creditAmountForm.get('amountCarryForward')?.value) +
          CommUtilsService.parseInputInt(this.creditAmountForm.get('amountFundingAllocation')?.value) +
          CommUtilsService.parseInputInt(this.creditAmountForm.get('amountCredit')?.value),
      ),
    });
    if (this.getErrorMessage(controlName)) {
      this.errorsA[controlName] = this.getErrorMessage(controlName);
    } else {
      delete this.errorsA[controlName];
    }
  }
  onFocusInA(controlName: string) {
    delete this.errorsA[controlName];
  }
  autoSave(controlName: string) {
    const assetManagementFundsData: Partial<SepoExtUpdateDto> = {};

    if (this.assetManagementForm.controls['hasAssetManagementPlan'].value)
      assetManagementFundsData.hasAssetManagementPlan = true;

    if (this.assetManagementForm.controls['hasAssetManagementPlanNo'].value)
      assetManagementFundsData.hasAssetManagementPlan = false;

    if (controlName == 'assetManagementPlanLastUpdatedAt') {
      if (this.assetManagementForm.controls['assetManagementPlanLastUpdatedAt'].value) {
        assetManagementFundsData.hasAssetManagementPlan =
          this.assetManagementForm.controls['hasAssetManagementPlan'].value;
        assetManagementFundsData.assetManagementPlanLastUpdatedAt =
          this.assetManagementForm.controls['assetManagementPlanLastUpdatedAt'].value;

        assetManagementFundsData.assetManagementPlanBarriers = [];
        assetManagementFundsData.assetManagementPlanOther = '';
      } else {
        assetManagementFundsData.assetManagementPlanLastUpdatedAt = '';
      }
    }

    if (controlName == 'assetManagementPlanBarriers') {
      if (
        this.assetManagementForm.controls['assetManagementPlanBarriers'].value &&
        this.assetManagementForm.controls['hasAssetManagementPlanNo'].value == true
      ) {
        assetManagementFundsData.hasAssetManagementPlan = false;
        assetManagementFundsData.assetManagementPlanBarriers = this.assetManagementForm.controls[
          'assetManagementPlanBarriers'
        ].value?.map((_: number) => {
          return {
            id: _,
            title: '',
          };
        });

        if (
          !(
            this.assetManagementForm
              .get('assetManagementPlanBarriers')
              ?.value?.indexOf(
                this.assetManagementPlanBarriers?.find((x) => x.title == 'Other (please describe)')?.id,
              ) > -1
          )
        ) {
          assetManagementFundsData.assetManagementPlanOther = '';
        }

        assetManagementFundsData.assetManagementPlanLastUpdatedAt = '';
      } else {
        assetManagementFundsData.assetManagementPlanBarriers = [];
        assetManagementFundsData.assetManagementPlanOther = '';
      }
    }

    if (controlName == 'assetManagementPlanOther') {
      if (this.assetManagementForm.controls['assetManagementPlanOther'].value) {
        assetManagementFundsData.assetManagementPlanOther =
          this.assetManagementForm.controls['assetManagementPlanOther'].value;
      } else {
        assetManagementFundsData.assetManagementPlanOther = '';
      }
    }

    if (controlName == 'amountCredit') {
      if (this.creditAmountForm.controls['amountCredit'].value) {
        assetManagementFundsData.amountCredit = CommUtilsService.parseInputInt(
          this.creditAmountForm.controls['amountCredit'].value,
        );
      } else {
        assetManagementFundsData.amountCredit = null;
      }
    }
    this.store.dispatch(new SetSEPOAssetManagementPlan(assetManagementFundsData));
  }
  get getAssetManagementPlanYes() {
    return this.assetManagementForm.get('hasAssetManagementPlan')?.value;
  }
  get getAssetManagementPlanNo() {
    return this.assetManagementForm.get('hasAssetManagementPlanNo')?.value;
  }
  get showAssetManagementPlanOtherSpecify() {
    return (
      this.getAssetManagementPlanNo &&
      this.assetManagementForm
        .get('assetManagementPlanBarriers')
        ?.value?.indexOf(this.assetManagementPlanBarriers?.find((x) => x.title == 'Other (please describe)')?.id) > -1
    );
  }
  private setRadioCheckedState(nameStr: string, checked: boolean) {
    const elemArray = document.getElementsByName(nameStr);
    elemArray.forEach((elem) => {
      if (elem.hasAttribute('checked')) {
        const checkedValue: string | undefined = elem.getAttribute('checked') ?? undefined;
        if (checkedValue !== undefined) {
          elem.setAttribute('checked', checked ? 'true' : 'false');
        }
      }
    });
  }
  public hasAssetManagementPlanClicked(answer: boolean) {
    if (answer === true) {
      this.assetManagementForm.controls['hasAssetManagementPlanNo'].setValue(false);
      this.setRadioCheckedState('hasAssetManagementPlanNo', false);
      this.assetManagementForm.controls['hasAssetManagementPlan'].setValue(true);
      this.setRadioCheckedState('hasAssetManagementPlan', true);
      this.autoSave('hasAssetManagementPlan');
    } else {
      this.assetManagementForm.controls['hasAssetManagementPlan'].setValue(false);
      this.setRadioCheckedState('hasAssetManagementPlan', false);
      this.assetManagementForm.controls['hasAssetManagementPlanNo'].setValue(true);
      this.setRadioCheckedState('hasAssetManagementPlanNo', true);
      this.autoSave('hasAssetManagementPlanNo');
    }
  }

  openedChangeBarrierList(event: boolean) {
    this.assetManagementPlanBarrierListClose = !event;
    const controlName = 'assetManagementPlanBarriers';
    this.autoSave(controlName);
    if (this.assetManagementPlanBarrierListClose == true) {
      if (this.getErrorMessage(controlName)) {
        this.errors[controlName] = this.getErrorMessage(controlName);
      }
    } else {
      delete this.errors[controlName];
    }
  }
}
